<!--bg-transparent MENU PRINCIPAL-->
<nav class="navbar navbar-expand-xl navbar-dark  fixed-top" >
  <div class="container header-container">
<!--    <a class="navbar-brand" href="#">Credi Agil</a>-->
    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
      <img src="assets/images/logo_hv.png" alt="CrediAgilLogo">
    </a>
    <button class="navbar-toggler border-0 navbar-inverse"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation">
      <span> <i class="bi bi-three-dots" style=" color: white; font-size:38px;"></i></span>
    </button>

    
    <div class="sidebar offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
<!--      Sidebar Header-->
<!--      <div class="offcanvas-header text-white border-bottom">-->
<!--        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Credi Ágil</h5>-->
<!--        <button type="button" class="bg-transparent border-0" data-bs-dismiss="offcanvas" aria-label="Close">-->
<!--          <span> <i class="bi bi-x-circle" style="color:#0037C6; font-size:28px;"></i></span>-->
<!--        </button>-->
<!--      </div>-->
      <div class="offcanvas-body" >
        <ul class="navbar-nav justify-content-center align-items-center fs-6 flex-grow-1 pe-1" data-bs-toggle="offcanvas">
          <li><a href="/" class="nav-link mx-2 active " title="Inicio">Inicio</a></li>
          <li><a routerLink="/nosotros"  href="/nosotros" class="nav-link mx-2 " title="Nosotros">Nosotros</a></li>
          <li><a routerLink="/app" class="nav-link mx-2 " title="Nuestra APP">Nuestra APP</a></li>
          <li><a routerLink="/simulador" class="nav-link mx-2 " title="Simulador">Simulador</a></li>
          <li><a routerLink="/credito" class="nav-link mx-2" title="Solicitá tu crédito">Solicitá tu crédito</a></li>
          <li><a routerLink="/sucursales" class="nav-link mx-2" title="Sucursales">Sucursales</a></li>
<!--          <li><a  routerLink="/communications" class="nav-link mx-2">Novedades</a></li>-->
          <li class="nav-item dropdown d-sm-none d-lg-block d-none">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" title="Novedades">
              Novedades <i class="bi bi-caret-down-fill"></i>
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" routerLink="/noticias" title="Noticias" href="#">Noticias</a></li>
              <li><a class="dropdown-item" routerLink="/comunicaciones"  href="#" title="Comunicaciones">Comunicaciones</a></li>
            </ul>
          </li>
          <li><a  routerLink="/noticias" class="nav-link mx-2 d-xl-none d-sm-block" title="Noticias">Noticias</a></li>
          <li><a  routerLink="/comunicaciones" class="nav-link mx-2 d-xl-none d-sm-block" title="Comunicaciones">Comunicaciones</a></li>
          <li><a routerLink="/contacto" class="nav-link mx-2" title="Contacto">Contacto</a></li>
          <li>
            <a routerLink="/minicreditos"  href="/minicreditos" class="nav-link mx-2 position-relative" title="Minicréditos">
              Minicréditos
              <span class="position-absolute start-100 translate-middle badge rounded-pill bg-success">
                Nuevo
                <span class="visually-hidden">Nuevo</span>
              </span>
            </a>
            <!-- <button type="button" class="btn btn-primary position-relative">
              Minicréditos
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                Nuevo
                <span class="visually-hidden">Nuevo</span>
              </span>
            </button> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<!--<header id="topnav" class="defaultscroll sticky">-->
<!--  <div class="container">-->

<!--    <div class="menu-extras">-->
<!--      <div class="menu-item">-->
<!--        &lt;!&ndash; Mobile menu toggle&ndash;&gt;-->
<!--        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">-->
<!--          <div class="lines">-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--          </div>-->
<!--        </a>-->
<!--        &lt;!&ndash; End mobile menu toggle&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->

<!--    <div id="navigation">-->
<!--      &lt;!&ndash; Navigation Menu&ndash;&gt;-->
<!--      <ul class="navigation-menu" [className]="miStyle">-->
<!--        <li><a routerLink="/" class="nav-link-ref">Home</a></li>-->

<!--        <li class="has-submenu">-->
<!--          <a>Docs</a><span class="menu-arrow"></span>-->
<!--          <ul class="submenu">-->
<!--            <li><a class="nav-link-ref" routerLink="/documentation">Documentation</a></li>-->
<!--            <li><a class="nav-link-ref" routerLink="/changelog">Changelog</a></li>-->
<!--            <li><a class="nav-link-ref" routerLink="/widget">Widget</a></li>-->
<!--          </ul>-->
<!--        </li>-->
<!--      </ul>-->
<!--      &lt;!&ndash;end navigation menu&ndash;&gt;-->
<!--      <div class="buy-menu-btn d-none">-->
<!--        <a href="javascript: void(0);" target="_blank" class="btn btn-primary">Buy Now</a>-->
<!--      </div>-->
<!--      &lt;!&ndash;end login button&ndash;&gt;-->
<!--      &lt;!&ndash;end login button&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->

<!--</header>-->

