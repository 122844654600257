import {afterNextRender, Component} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import AOS from "aos";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.css'
})
export class AboutComponent {
  constructor(
    private gtmService: GoogleTagManagerService,
    private router: Router,
  ) {
    afterNextRender(() =>
      {
        AOS.init( {
          once: false
        });
        this.router.events.forEach(item =>{
          if(item instanceof NavigationEnd){
            console.log("NavigationEnd",item);
            const gtmTag = {
              event: "Nosotros",
              pageName: item.url
            };
            this.gtmService.pushTag(gtmTag);
          }
        })
      }
    )
  }

  ngOnInit(): void {
    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }

}
