import {afterNextRender, Component, inject, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import { Observable, take } from 'rxjs';
import { SessionService } from '../../services/dashboard/session.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  isCondensed:boolean = false;
  miStyle = 'display: none';
  sessionService = inject(SessionService);
  router = inject(Router);
  isUserAuthenticated$: Observable<boolean | undefined>;
  ngOnInit(): void {
  }

  constructor() {
    this.isUserAuthenticated$ = this.sessionService.verificarLocalStore();
  }

  /**
   * Redirige a al dashboard si esta logueado, sino 
   * redirige a la pantalla de login
   */
  navigate(){
    this.isUserAuthenticated$.pipe(take(1)).subscribe(isLogged => {
      if(isLogged){
        this.router.navigateByUrl('/mis-solicitudes');
      }
      else{
        this.router.navigateByUrl('/solicitud-credito/login');
      }
    })
  }

  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      this.miStyle = 'display: block';
      //document.getElementById("navigation").style.display = "block";
    } else {
      this.miStyle = 'display: none';
      //document.getElementById("navigation").style.display = "none";
    }
  }
}
