<section>
    <ul class="steps">
        <li data-aos="fade-up">
            <img src="./../assets/images/minicreditos/step-1-download.png" class="img-desktop" width="200" alt="Icono de descargar app"/>
            <img src="./../assets/images/minicreditos/step-1-download.png" class="img-mobile" width="100" alt="Icono de descargar app"/>
            <div class="steps-list-text">
                <p>Descargá la app</p>
                <p>Bajá la app desde el Play Store y tenela lista para empezar</p>
            </div>

        </li>
        <li data-aos="fade-up">
            <img src="./../assets/images/minicreditos/step-2-register.png" class="img-desktop" width="200" alt="Icono de descargar app"/>
            <img src="./../assets/images/minicreditos/step-2-register.png" class="img-mobile" width="100" alt="Icono de descargar app"/>
            <div class="steps-list-text">
                <p>Registrate</p>
                <p>Crea tu cuenta en pocos pasos</p>
            </div>

        </li>
        <li data-aos="fade-up">
            <img src="./../assets/images/minicreditos/step-3-identity.png" class="img-desktop" width="200" alt="Icono de validar identidad"/>
            <img src="./../assets/images/minicreditos/step-3-identity.png"  class="img-mobile" width="100" alt="Icono de validar identidad"/>
            <div class="steps-list-text">
                <p>Valida tu identidad</p>
                <p>Valida tu identidad. No olvides tener tu CI a mano!</p>
            </div>

        </li>
        <li data-aos="fade-up">
            <img src="./../assets/images/minicreditos/step-4-wallet.png" class="img-desktop" width="200" alt="Icono de activar la billetera"/>
            <img src="./../assets/images/minicreditos/step-4-wallet.png" class="img-mobile" width="100" alt="Icono de activar la billetera"/>
            <div class="steps-list-text">
                <p>Activa tu billetera</p>
                <p>Activa tu Billetera Ágil desde la app para poder solicitar Minicréditos.</p>
            </div>

        </li>
        <li data-aos="fade-up">
            <img src="./../assets/images/minicreditos/step-5-request.png" class="img-desktop" width="200" alt="Icono de solicitar minicrédito"/>
            <img src="./../assets/images/minicreditos/step-5-request.png" class="img-mobile" width="100" alt="Icono de solicitar minicrédito"/>
            <div class="steps-list-text">
                <p>Pedí un Minicrédito</p>
                <p>Ve a la sección de "Préstamos" dentro de la app y solicitá el monto que necesites</p>
            </div>

        </li>
    </ul>
    <a href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL" target="_blank">Descargá la App</a>
</section>