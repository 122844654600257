import { Component, inject, OnInit } from '@angular/core';
import { HeroComponent } from './hero/hero.component';
import { StepsComponent } from './steps/steps.component';
import { IntroComponent } from './intro/intro.component';
import { FeaturesComponent } from './features/features.component';
import { FaqsComponent } from './faqs/faqs.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { NavigationEnd, Router } from '@angular/router';
import AOS from 'aos';
import {GoogleTagManagerModule, GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-minicreditos',
  standalone: true,
  imports: [HeroComponent, StepsComponent, IntroComponent, FeaturesComponent, FaqsComponent, QrCodeComponent],
  templateUrl: './minicreditos.component.html',
  styleUrl: './minicreditos.component.css'
})
export class MinicreditosComponent implements OnInit {
  router = inject(Router);
  gtmService = inject(GoogleTagManagerService);


  ngOnInit(): void {
    AOS.init( {
      once: false
    });
    this.router.events.forEach(item =>{
      if(item instanceof NavigationEnd){
        const gtmTag = {
          event: "minicreditos",
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    })

    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }
}
