import {afterNextRender, Component, signal, TemplateRef} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-ourapp',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './ourapp.component.html',
  styleUrl: './ourapp.component.css'
})
export class OurappComponent {
  isMobile = signal(false);
//isMobile:boolean = false;
  constructor(
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private modalService: NgbModal
  ) {
    afterNextRender(() => {
      this.isMobile.set(window.screen.width <= 768);
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          console.log("NavigationEnd", item);
          const gtmTag = {
            event: "app",
            pageName: item.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      })
    });
  }

  openWindowCustomClass(content: TemplateRef<any>) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

  ngOnInit(): void {
    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }
}
