<div style="padding-bottom: 120px">
  <section class="bg-half-170 d-table w-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center" data-aos="zoom-in">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">SOBRE NOSOTROS</h4>
            <p class="para-desc mb-0 mx-auto">Hace más de 18 años ayudamos a personas a cumplir sueños y alcanzar sus objetivos mediante el acceso a créditos personales de manera rápida, fácil, confiable y con mínimos requisitos. </p>
            <p>Así nos convertimos en la <b>casa de crédito más grande de Paraguay</b> con</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row">
        <div class="col-md-3 col-6 mt-4 pt-2">
          <div class="counter-box text-center">
            <img src="assets/images/icono/icono-trayectoria.png" alt="" class="img-about">
            <p class="title-about">+18</p>
            <p class="subtitle-about">AÑOS</p>
          </div>
        </div>


        <div class="col-md-3 col-6 mt-4 pt-2">
          <div class="counter-box text-center">
            <img src="assets/images/icono/icono-Clientes.png" alt="" class="img-about">
            <p class="title-about">+140.000</p>
            <p class="subtitle-about">CLIENTES</p>
          </div>
        </div>

        <div class="col-md-3 col-6 mt-4 pt-2">
          <div class="counter-box text-center">
            <img src="assets/images/icono/icono-colaboradores.png" alt="" class="img-about">
            <p class="title-about">+1.500</p>
            <p class="subtitle-about">COLABORADORES</p>
          </div>
        </div>

        <div class="col-md-3 col-6 mt-4 pt-2">
          <div class="counter-box text-center">
            <img src="assets/images/icono/icono-sucursales.png" alt="" class="img-about">
            <p class="title-about">+70</p>
            <p class="subtitle-about">SUCURSALES</p>
          </div>
        </div>

      </div>

      <!--end row-->
    </div>
    <!--end container-->
  </section>

  <section class="section border-bottom bg-light">
    <div class="container ">
      <div class="row justify-content-center">
        <div class="col-12" data-aos="zoom-in">
          <div class="section-title text-center mb-4 pb-2">
            <img src="assets/images/mision.png" alt="" width="77px">
            <h4 class="title mb-4 mt-4">MISIÓN</h4>
            <p data-aos="fade-up">Somos una empresa AMIGABLE que busca satisfacer con una excelente atención y rapidez las exigencias de sus clientes, construyendo relaciones a largo plazo a través del otorgamiento de créditos personales y para microempresas, ayudando a nuestros clientes a concretar sus propios proyectos.</p>
            <p  data-aos="fade-up">Queremos que nuestros clientes se sientan como en casa.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12" data-aos="zoom-in">
          <div class="section-title text-center mb-4 pb-2">
            <img src="assets/images/vision.png" alt="" width="77px">
            <h4 class="title mb-4 mt-4">VISIÓN</h4>
            <p  data-aos="fade-up">Ser una empresa con identidad propia, única en el sistema de otorgamiento de créditos al segmento de microempresarios y asalariados con buena cultura de pago.</p>
            <p  data-aos="fade-up">
              Una empresa que además de otorgar créditos sirva como instrumento para el progreso y el logro de los objetivos de la gente y que tenga como valor principal el desarrollo de sus funcionarios, clientes y familias.</p>
            <p  data-aos="fade-up">
              Donde cada cliente este satisfecho por la excelencia en la atención, por la agilidad y rapidez de nuestros procesos, por los créditos accesibles y por el profesionalismo de nuestra gente.</p>
            <p data-aos="fade-up">Que los accionistas alcancen el retorno esperado de su inversión y que la sociedad encuentre en nosotros una empresa que respeta las normas vigentes, con crecimiento firme y seguro, donde se desarrollen lazos estrechos y de largo plazo con clientes y proveedores.</p>
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12" data-aos="zoom-in">
          <div class="section-title text-center mb-4 pb-2">
            <img src="assets/images/valores.png" alt="" width="77px">
            <h4 class="title mb-4 mt-4">VALORES</h4>
            <ul class="list-unstyled">
              <li class="mb-0" data-aos="fade-up">Respeto</li>
              <li class="mb-0" data-aos="fade-up">Humildad</li>
              <li class="mb-0" data-aos="fade-up">Solidaridad</li>
              <li class="mb-0" data-aos="fade-up">Profesionalismo</li>
              <li class="mb-0" data-aos="fade-up">Trabajo en equipo </li>
              <li class="mb-0" data-aos="fade-up">Calidad de trabajo</li>
            </ul>
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->
  </section>
  <!--end section-->


</div>
