<!--
<section class="section">
  <div class="container">
    <div class="card shadow rounded border-0 overflow-hidden">
      <div class="row g-0">
        <div class="col-md-12">
          <h2>{{item['title']}}</h2>
          <div [innerHTML]="item['content']">

          </div>
&lt;!&ndash;          <div class="card-body">&ndash;&gt;
&lt;!&ndash;            <h4 class="card-title">{{item.title}}</h4>&ndash;&gt;
&lt;!&ndash;            <h5 class="card-title">{{item.subtitle}}</h5>&ndash;&gt;
&lt;!&ndash;            <p class="card-text">{{item.contenido[0].value}}</p>&ndash;&gt;
&lt;!&ndash;            <img src="{{item.url}}" class="img-fluid" alt="...">&ndash;&gt;
&lt;!&ndash;            <div [innerHTML]="item.contenido[2].value" class="card-text"></div>&ndash;&gt;
&lt;!&ndash;            <div [innerHTML]="item.contenido[3].value" class="card-text"></div>&ndash;&gt;
&lt;!&ndash;            <div [innerHTML]="item.contenido[4].value" class="card-text"></div>&ndash;&gt;
&lt;!&ndash;          </div>&ndash;&gt;
        </div>
      </div>
    </div>
    <div class="mt-5">
      <a routerLink="/credito" class="btn btn-pills btn-primary"><b>SOLICITAR AHORA</b></a>
    </div>
  </div>
</section>-->


<!-- Blog STart -->
<!--<section class="section">
  <div class="container">
    <div class="row">
      &lt;!&ndash; BLog Start &ndash;&gt;
      <div class="col-lg-8 col-md-6">
        <div class="me-lg-5">
&lt;!&ndash;          <img src="assets/images/noticias/simulador-de-credito.png" class="img-fluid rounded-md shadow" alt="">&ndash;&gt;

          <h1 class="mt-4">{{item.title}}</h1>
          <div [innerHTML]="item['content']">

          </div>

&lt;!&ndash;          <p class="text-muted">Demo</p>&ndash;&gt;

         &lt;!&ndash; <h5 class="mt-4">Comentario:</h5>

          <ul class="media-list list-unstyled mb-0">
            <li class="mt-4">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <a class="pe-3" href="javascript:void(0)">
                    <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                  </a>
                  <div class="flex-1 commentor-detail">
                    <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a></h6>
                    <small class="text-muted">16th August, 2021 at 03:44 pm</small>
                  </div>
                </div>
                <a href="javascript:void(0)" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
              </div>
              <div class="mt-3">
                <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
              </div>

              <ul class="list-unstyled ps-4 ps-md-5 sub-comment">
                <li class="mt-4">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <a class="pe-3" href="javascript:void(0)">
                        <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                      </a>
                      <div class="flex-1 commentor-detail">
                        <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo Peterson</a></h6>
                        <small class="text-muted">17th August, 2021 at 01:25 pm</small>
                      </div>
                    </div>
                    <a href="javascript:void(0)" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                  </div>
                  <div class="mt-3">
                    <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <h5 class="mt-4">Deja un comentario:</h5>

          <form class="mt-3">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Tu comentario</label>
                  <div class="form-icon position-relative">
&lt;!&ndash;                    <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>&ndash;&gt;
                    <textarea id="message" placeholder="tu comentario" rows="5" name="message" class="form-control ps-5" required=""></textarea>
                  </div>
                </div>
              </div>&lt;!&ndash;end col&ndash;&gt;

              <div class="col-lg-6">
                <div class="mb-3">
                  <label class="form-label">Name <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
&lt;!&ndash;                    <i-feather name="user" class="fea icon-sm icons"></i-feather>&ndash;&gt;
                    <input id="name2" name="name" type="text" placeholder="Name" class="form-control ps-5" required="">
                  </div>
                </div>
              </div>&lt;!&ndash;end col&ndash;&gt;

              <div class="col-lg-6">
                <div class="mb-3">
                  <label class="form-label">Your Email <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
&lt;!&ndash;                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>&ndash;&gt;
                    <input id="email2" type="email" placeholder="Email" name="email" class="form-control ps-5" required="">
                  </div>
                </div>
              </div>&lt;!&ndash;end col&ndash;&gt;

              <div class="col-md-12">
                <div class="send d-grid">
                  <button type="submit" class="btn btn-primary">Enviar mensaje</button>
                </div>
              </div>&lt;!&ndash;end col&ndash;&gt;
            </div>&lt;!&ndash;end row&ndash;&gt;
          </form>&lt;!&ndash;end form&ndash;&gt;&ndash;&gt;
        </div>
      </div>&lt;!&ndash;end col&ndash;&gt;
      &lt;!&ndash; BLog End &ndash;&gt;

      &lt;!&ndash; START SIDEBAR &ndash;&gt;
      <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 sidebar sticky-bar rounded shadow">
          <div class="card-body">
            &lt;!&ndash; SEARCH &ndash;&gt;
            <div class="widget">
              <form role="search" method="get">
                <div class="input-group mb-3 border rounded">
                  <input type="text" id="s" name="s" class="form-control border-0" placeholder="Buscar...">
                  <button type="submit" class="input-group-text bg-transparent border-0" id="searchsubmit"><i class="uil uil-search"></i></button>
                </div>
              </form>
            </div>
            &lt;!&ndash; SEARCH &ndash;&gt;

            &lt;!&ndash; Categories &ndash;&gt;
            <div class="widget mb-4 pb-2">
              <h5 class="widget-title">Categorías</h5>
              <ul class="list-unstyled mt-4 mb-0 blog-categories">
                <li><a href="jvascript:void(0)">Consejos Financieros</a> <span class="float-end">01</span></li>
                <li><a href="jvascript:void(0)">Simuladores y Calculadoras</a> <span class="float-end">02</span></li>
                <li><a href="jvascript:void(0)">Préstamos en Línea</a> <span class="float-end">18</span></li>
                <li><a href="jvascript:void(0)">Beneficios de Préstamos</a> <span class="float-end">20</span></li>
                <li><a href="jvascript:void(0)">Guías y Tips</a> <span class="float-end">22</span></li>
              </ul>
            </div>
            &lt;!&ndash; Categories &ndash;&gt;

            &lt;!&ndash; RECENT POST &ndash;&gt;
            <div class="widget mb-4 pb-2">
              <h5 class="widget-title">Publicación reciente</h5>
              <div class="mt-4">
                <div class="d-flex align-items-center">
                  <img src="assets/images/blog/01.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                  <div class="flex-1 ms-3">
                    <a href="javascript:void(0)" class="d-block title text-dark">Consultant Business</a>
                    <span class="text-muted">15th April 2021</span>
                  </div>
                </div>

                <div class="d-flex align-items-center mt-3">
                  <img src="assets/images/blog/02.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                  <div class="flex-1 ms-3">
                    <a href="javascript:void(0)" class="d-block title text-dark">Grow Your Business</a>
                    <span class="text-muted">15th April 2021</span>
                  </div>
                </div>

                <div class="d-flex align-items-center mt-3">
                  <img src="assets/images/blog/03.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                  <div class="flex-1 ms-3">
                    <a href="javascript:void(0)" class="d-block title text-dark">Look On The Glorious Balance</a>
                    <span class="text-muted">15th April 2021</span>
                  </div>
                </div>
              </div>
            </div>
            &lt;!&ndash; RECENT POST &ndash;&gt;

            &lt;!&ndash; SOCIAL &ndash;&gt;
            <div class="widget">
              <h5 class="widget-title">Síganos</h5>
              <ul class="list-unstyled social-icon social mb-0 mt-4">
                <li class="list-inline-item">
                  <a href="https://www.facebook.com/Crediagil.py/?locale=es_LA" style="font-size: 22px;" title="Facebook">
                    <i class="bi bi-facebook fa-lg"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="https://www.instagram.com/crediagil.py/?hl=es"  style="font-size: 22px;" title="Instagram">
                    <i class="bi bi-instagram fa-10x"></i>
                  </a>
                </li>
              </ul>
            </div>
            &lt;!&ndash; SOCIAL &ndash;&gt;
          </div>
        </div>
      </div>&lt;!&ndash;end col&ndash;&gt;
      &lt;!&ndash; END SIDEBAR &ndash;&gt;
    </div>
  </div>
</section>-->


<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row">
          <div class="col-md-12">

            <ul class="list-unstyled d-flex justify-content-between mt-4">
              <li class="list-inline-item user me-2"><a href="javascript:void(0)" class="text-muted"><i
                class="uil uil-user text-dark"></i> {{item.name}}</a></li>
              <li class="list-inline-item date text-muted"><i class="uil uil-calendar-alt text-dark"></i> {{item.date}}</li>
            </ul>

            <h1 class="mt-4">{{item.title}}</h1>
            <div [innerHTML]="item['content']">

            </div>

            <h5 class="mt-4">Comentarios:</h5>

            <ul class="media-list list-unstyled mb-0">
              <li class="mt-4">
               <!-- <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <a class="pe-3" href="javascript:void(0)">
                      <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                           alt="img">
                    </a>
                    <div class="flex-1 commentor-detail">
                      <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo Peterson</a>
                      </h6>
                      <small class="text-muted">15th August, 2019 at 01:25 pm</small>
                    </div>
                  </div>
                  <a  class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                </div>-->
                <div class="mt-3">
                  <p class="text-muted fst-italic p-3 bg-light rounded">" No hay comentarios"</p>
                </div>
              </li>
            </ul>

            <h5 class="mt-4">Deja un comentario:</h5>

            <form class="mt-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Tu comentario</label>
                    <div class="form-icon position-relative">
                      <textarea id="message" placeholder="Tu comentario" rows="5" name="message"
                                class="form-control ps-5" required=""></textarea>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Nombre y Apellido <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <input id="name" name="name" type="text" placeholder="Nombre y Apellido" class="form-control ps-5" required="">
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Correo electrónico <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <input id="email" type="email" placeholder="Correo electrónico" name="email" class="form-control ps-5"
                             required="">
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="send d-grid">
                    <button type="submit" class="btn btn-primary" disabled>Enviar mensaje</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
