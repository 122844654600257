<div class="wrapper example-sidenav-toggle">

   <aside id="sidebar">
      <div class="h-100">
         <!-- <div class="sidebar-logo">

            <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
               <img src="assets/images/logo_hv.png" alt="CrediAgilLogo">
             </a>
         </div> -->
         <!-- Sidebar Navigation -->
         <ul class="sidebar-nav">
            <!-- <li class="sidebar-item">
                  <a href="#" class="sidebar-link collapsed d-flex" data-bs-toggle="collapse" data-bs-target="#pages"
                     aria-expanded="false" aria-controls="pages">
                     <span class="material-symbols-outlined">
                        travel
                        </span>
                     Paquetes de viajes
                  </a>
                  <ul id="pages" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                     <li class="sidebar-item">
                        <a href="#" class="sidebar-link item-menu">Terrestres</a>
                     </li>
                     <li class="sidebar-item">
                        <a href="#" class="sidebar-link item-menu">Aereos</a>
                     </li>
                  </ul>
            </li> -->
           
            <!-- <li class="sidebar-item">
                  <a href="#" class="sidebar-link collapsed d-flex" data-bs-toggle="collapse" data-bs-target="#dashboard"
                     aria-expanded="true" aria-controls="dashboard">
                     <span class="material-icons">security</span>
                     Seguridad
                  </a>
                  <ul id="dashboard" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                     <li class="sidebar-item">
                        <a href="/usuario" id="menu-usuario" class="sidebar-link item-menu">Usuarios</a>
                     </li>
                     <li class="sidebar-item">
                        <a href="/empleado" id="menu-empleado" class="sidebar-link item-menu">Empleados</a>
                     </li>
                     <li class="sidebar-item">
                        <a href="/persona" id="menu-persona" class="sidebar-link item-menu">Personas</a>
                     </li>
                     <li class="sidebar-item">
                        <a href="/rol" id="menu-rol" class="sidebar-link item-menu">Roles</a>
                     </li>
                     <li class="sidebar-item">
                        <a href="/permiso" id="menu-permiso" class="sidebar-link item-menu">Permisos</a>
                     </li>
                  </ul>
            </li> -->

               <li class="sidebar-item">
                  <a routerLink="/mis-solicitudes" routerLinkActive="is-active" class="sidebar-link collapsed item-menu d-flex" >
                     <i class="bi bi-cash"></i> <span class="item-nav-text">Mis solicitudes</span>
                  </a>
               </li>

               <!-- <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Dropdown button
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </div>
                </div> -->
               
               
               <!-- <li class="sidebar-item">
                  <a href="#" class="sidebar-link collapsed d-flex" data-bs-toggle="collapse" data-bs-target="#reportes"
                  aria-expanded="false" aria-controls="reportes">
                  <span class="material-icons">description</span>
                  Solicitudes de creditos
               </a>
               <ul id="reportes" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                  <li class="sidebar-item">
                     <a href="#" class="sidebar-link item-menu">Mis solicitudes</a>
                  </li>
               </ul>
            </li> -->
         </ul>
      </div>
   </aside>
   
</div>