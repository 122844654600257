import { NgClass } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [NgClass],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.css'
})
export class FaqsComponent {
  faqs = [
    { id: 1, question: "¿Cómo puedo solicitar mi Minicrédito?", answer: "Los Minicréditos solo están disponibles a través de la Billetera Ágil y tienen cupo limitado. Descargá la app y solicitalo cuanto antes!" },
    { id: 2, question: "¿Puedo solicitar más de 500.000 Gs?", answer: "La Billetera Ágil permite solicitar montos mayores, pero no son considerados como Minicréditos y el proceso de aprobación es diferente." }, 
    { id: 3, question: "¿Cómo desembolso mi Minicrédito?", answer: "Solo es posible desembolsar el Minicrédito a través de la Billetera Ágil." },
    { id: 4, question: "¿Cómo pago la cuota de mi Minicrédito?", answer: "Podés pagar la cuota a través de la app, en sucursales de Crediágil y a través de bocas de cobranza. Cualquiera de los 3 medios de pago es válido." },
    { id: 5, question: "¿Puedo tener un Minicrédito y un crédito tradicional a la vez?", answer: "Nuestra política es que solo puedes tener un Minicrédito o un crédito tradicional, no ambos. Si tenés un crédito vigente, deberás cancelarlo antes de poder solicitar otro tipo de producto." },
    { id: 6, question: "¿Por qué no veo la opción del Minicrédito?", answer: " Si no te aparece la opción de tu Minicrédito, no te preocupes. En esta etapa el Minicrédito está disponible para un grupo de usuarios elegido de forma aleatoria." },];

  isOpen = Array(this.faqs.length).fill(false);

  toggle(index: number): void {
    this.isOpen[index] = !this.isOpen[index];
  }
}
