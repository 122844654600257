<!-- TOOLBAR -->
<!-- <p class="pb-0 mb-0 bg-blue-400"> -->
    <app-toolbar></app-toolbar>
  <!-- </p> -->
  <!--FIN TOOLBAR -->
  
  <div class="example-container" autosize>
  
      <!--SIDEBAR-->
      <div class="example-sidenav example-sidenav-toggle" mode="side">
        <app-nav-menu></app-nav-menu>
      </div>
      <!-- FIN SIDEBAR-->
    
      <!-- MAIN WRAPPER -->
      <div class="example-sidenav-content">
          <main class="pb-14">
            <router-outlet>
            </router-outlet>
          </main>
      </div>
      <!-- FIN MAIN WRAPPER -->
    
  </div>
  