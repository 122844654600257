<section class="latest-news">
    <h5>Últimas Noticias</h5>
    <div class="latest-news-container">
        @for (n of latestNews; track n.id) {
            <app-latest-news-item 
            date={{n.date}}  
            title={{n.title}}
            description={{n.description}}
            thumbnailAlt={{n.thumbnailAlt}}
            thumbnailUrl={{n.thumbnailUrl}}
            pathUrl={{n.pathUrl}}
            data-aos="fade-up"
            data-aos-offset="250"
            />
        }
    </div>

</section>



