<!--bg-transparent MENU PRINCIPAL-->
<nav class="navbar navbar-expand-lg navbar-dark" >
    <div class="container header-container">
  <!--    <a class="navbar-brand" href="#">Credi Agil</a>-->
      <a [routerLink]="['/mis-solicitudes']" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <img src="assets/images/logo_hv.png" alt="CrediAgilLogo">
      </a>
      <button class="navbar-toggler border-0 navbar-inverse"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation">
        <span> <i class="bi bi-three-dots" style=" color: white; font-size:38px;"></i></span>
      </button>

      <div class="sidebar offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-body" >
          <ul class="navbar-nav justify-content-center align-items-center fs-6 flex-grow-1 pe-1" data-bs-toggle="offcanvas">
            <li><a [routerLink]="['/mis-solicitudes']" class="nav-link mx-2 active " title="Inicio">Mis solicitudes</a></li>
          </ul>
        </div>
      </div>

      <div class="user-name">
        Victor Cubas
      </div>

      <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle" (click)="onToogleMenu()"
            type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bi bi-gear"></i>
        </button>
        
        @if(toogleMenu){
          <div class="dropdown-menu dropdown-menu-toolbar" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="logout()" ><i class="bi bi-box-arrow-right"></i> <span class="item-nav-text">Logout</span></a>
            <!-- <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a> -->
          </div>
        }
      </div>
    </div>
  </nav>
  