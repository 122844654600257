import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateHtml'
})
export class TruncateHtmlPipe implements PipeTransform {

  transform(value: string, limit: number): string {
    if (!value) return '';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const truncatedText = textContent.split(/\s+/).slice(0, limit).join(' ') + '...';
    return truncatedText;
  }

}
