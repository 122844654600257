import {afterNextRender, AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID} from '@angular/core';
import AOS from 'aos';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterState} from "@angular/router";
import {GoogleTagManagerModule, GoogleTagManagerService} from "angular-google-tag-manager";
import {DOCUMENT} from "@angular/common";
import {AppModule} from "../../app.module";
import { HeroComponent } from '../minicreditos/hero/hero.component';
import { LatestNewsComponent } from '../latest-news/latest-news.component';
import { ContactBannerComponent } from '../contact-banner/contact-banner.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    RouterLink,
    AppModule,
    HeroComponent,
    LatestNewsComponent,
    ContactBannerComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent{
  //@ViewChild('content') contentRef: ElementRef;
  constructor(
    private  gtmService: GoogleTagManagerService,
    private router: Router,
    private route: ActivatedRoute,
    
  ) {
    afterNextRender(() => {
      AOS.init( {
        once: false
      });
      this.router.events.forEach(item =>{
        if(item instanceof NavigationEnd){
          console.log("NavigationEnd",item);
          const gtmTag = {
            event: "inicio",
            pageName: item.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      })
    }
    )
  }


  ngOnInit(): void {
    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }
  // trackMe() {
  //   console.log("hello");
  //   // push GTM data layer with a custom event
  //   const gtmTag = {
  //     event: "my-btn",
  //     data: "my-custom-event"
  //   };
  //   this.gtmService.pushTag(gtmTag);
  // }

  // handleRouteEvents() {
  //   this.router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
  //       //this.titleService.setTitle(title);
  //       gtag('event', 'page_view', {
  //         page_title: title,
  //         page_path: event.urlAfterRedirects,
  //         page_location: this.document.location.href
  //       })
  //     }
  //   });
  // }

  // getTitle(state: RouterState, parent: ActivatedRoute): string[] {
  //   const data = [];
  //   if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
  //     data.push(parent.snapshot.data['title']);
  //   }
  //   if (state && parent && parent.firstChild) {
  //     data.push(...this.getTitle(state, parent.firstChild));
  //   }
  //   return data;
  // }

}
