import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'app-nav-menu',
  standalone: true,
  imports: [SharedModule, RouterModule],
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.css'
})
export class NavMenuComponent {
  // activeMenu: number = -1;

  // constructor(private authService: AuthService,
  //   private router: Router
  // ){
    
  // }

  // toggleSubMenu(activeMenu: number) {
  //   if(this.activeMenu === activeMenu){
  //     this.activeMenu = -1;
  //     return;
  //   }

  //   this.activeMenu = activeMenu;
  // }

  // logout(){
  //   this.authService.logout()
  //   this.router.navigate(['auth'], {})
  // }

  // clearStorage(url?: string){
  //     //visita comercios crm
  //     localStorage.removeItem("filtroVisitas");
  //     //comercios crm
  //     localStorage.removeItem("filtroComercios");

  //     localStorage.removeItem("filtroAccesos");
  //     localStorage.removeItem("datosFiltroAccesos");
  //     localStorage.removeItem("filterFieldsBaseAccesos");
  //     // ver en pama visita
  //     localStorage.removeItem("visitaEnMapa");
  //     // ver en pama visita prospecto
  //     localStorage.removeItem("visitasProspectosEnMapa");

  //     //visita comercios prospectos
  //     localStorage.removeItem("filtroVisitaProspecto");

  //     //comercios prospectos
  //     localStorage.removeItem("filtroComerciosProspecto");
      
  // }
}
