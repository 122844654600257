import {Component, inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ContactService} from "../../services/contact.service";
import {LocalServiceService} from "../../services/local-service.service";
import {NgClass} from "@angular/common";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent  implements OnInit{
  contactService = inject(ContactService);
  contactForm!: FormGroup;
  submitted= false;
  constructor(
    //private modalService: NgbModal
    private  gtmService: GoogleTagManagerService,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.createCreditForm();
  }

  ngOnInit(): void {
    this.router.events.forEach(item =>{
      if(item instanceof NavigationEnd){
        console.log("NavigationEnd",item);
        const gtmTag = {
          event: "contacto",
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    })

    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }

  mapView(content : any) {
    // this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  onSubmit() {
    this.submitted = true;
    // console.log('Your form data : ', this.contactForm.value );
     console.log('Your form valid : ', this.contactForm.valid );
    this.contactForm.reset()
  }

  getTokens() {

  }


  private createCreditForm() {
    this.contactForm = this.fb.group({
      nombre: this.fb.control<string | ''>('', Validators.required),
      // apellido: this.fb.control<string | ''>('', Validators.required),
      email: [''],
      celular_numero: this.fb.control<string | ''>('', Validators.required),
      opciones: this.fb.control<string | ''>('', Validators.required),
      mensajes: this.fb.control<string | ''>('', Validators.required)
    });
  }

  get f(): { [key: string]: AbstractControl } {
    //console.log('get f', this.creditForm.controls)
    return this.contactForm.controls;
  }
}
