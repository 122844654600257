import {Component, inject, input, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {NewsService} from "../../services/news.service";
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-news-details',
  standalone: true,
    imports: [
        RouterLink
    ],
  templateUrl: './news-details.component.html',
  styleUrl: './news-details.component.css'
})
export class NewsDetailsComponent implements OnInit{

  private serviceNews = inject(NewsService);
  item: any;

  constructor(
    private activateRoute: ActivatedRoute,
    private meta: Meta
  ) {
  }

  setMetaTags(title: string, description: string) {
    this.meta.updateTag({ name: title, content: description });
  }

  ngOnInit(): void {
    const slug = this.activateRoute.snapshot.params['id'];
    this.serviceNews.getNewsBySlug(slug).then(news => {
      this.item = news;
      this.setMetaTags('title', this.item.title);
      this.setMetaTags('description', this.item.metaDescription);
    });

    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }
}
