import { afterNextRender, AfterRenderPhase, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, inject, Injector, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {OfficesService} from "../../services/offices.service";
import {CommonModule, isPlatformBrowser, JsonPipe} from "@angular/common";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {NavigationEnd, Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import * as L from 'leaflet';



@Component({
  selector: 'app-offices',
  standalone: true,
  imports: [CommonModule, JsonPipe, ReactiveFormsModule],
  templateUrl: './offices.component.html',
  styleUrl: './offices.component.css'
})
export class OfficesComponent implements OnInit {
  search = new FormControl('');
  officesService = inject(OfficesService);
  offices: any[] = [];
  officesFilter: any[] = [];
  screenWidth!: number;
  iconDefault: any;
  selectedOffice: any = null;
  isMarkerClick: any = false;

  private L: any;
  private map: any;
  private marker: any;
  private isBrowser: boolean; 
  
  constructor(
    private  gtmService: GoogleTagManagerService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.search.valueChanges.pipe().subscribe((value) => {
      if (typeof value === "string") {
        this.officesFilter = this.offices.filter((office: {
            nombre: string;
            direccion: string;
          }) =>
            office.nombre.toLowerCase().includes(value.toLowerCase()) ||
            office.direccion.toLowerCase().includes(value.toLowerCase())
        );
      }
    });

    this.isBrowser = isPlatformBrowser(this.platformId); 

  }
  getSucursales() {
    this.officesService.getSucursales().subscribe((data:any) => {
      this.offices = data['data'];
      this.officesFilter = data['data'];
      console.log('valor de data: ', data)
    });
  }


  async ngOnInit(): Promise<void> {
    this.getSucursales();
  
    if (isPlatformBrowser(this.platformId)) {
      this.debounce(async () => {
        const { default: leaflet } = await import('leaflet');
        this.L = leaflet;
        this.map = this.L;
  
        // Espera hasta que las sucursales estén cargadas
        this.officesService.getSucursales().subscribe((data: any) => {
          this.offices = data['data'];
          this.officesFilter = data['data'];
  
          // Carga el mapa después de obtener las sucursales
          this.loadLeafletMap();
        });
      }, 100);
    }
  }

  
    private debounce(func: () => void, wait: number): void {
      setTimeout(() => {
        func();
      }, wait);
    }
  
    private loadLeafletMap(): void {
      this.map = this.L.map('map').setView([-25.283046, -57.636192], 6);
    
      this.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(this.map);
    
      this.iconDefault = this.L.icon({
        iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
        iconUrl: 'assets/leaflet/marker-icon.png',
        shadowUrl: 'assets/leaflet/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        shadowSize: [41, 41],
        shadowAnchor: [12, 41],
      });
    
      // Agregar marcadores con evento `click`
      if (this.offices) {
        this.offices.forEach((office: any) => {
          const [lat, lng] = office.coordenadas
            ? office.coordenadas.split(',').map(Number)
            : [null, null];
    
          if (this.isValidLatLng(lat, lng)) {
            const marker = this.L.marker([lat, lng], { icon: this.iconDefault })
            .bindPopup(`<strong>${office.nombre}</strong><br><br>${office.direccion}`)
              .addTo(this.map);
    
            // Agregar evento `click` al marcador
            marker.on('click', () => {
              this.updateMap(`${lat},${lng}`,office.nombre,true);
            });
          } else {
            console.warn(`Coordenadas inválidas para la sucursal: ${office.nombre}`);
          }
        });
      }
    }


    // Método auxiliar para validar latitud y longitud
private isValidLatLng(lat: number, lng: number): boolean {
  return (
    typeof lat === 'number' &&
    typeof lng === 'number' &&
    !isNaN(lat) &&
    !isNaN(lng) &&
    lat >= -90 &&
    lat <= 90 &&
    lng >= -180 &&
    lng <= 180
  );
}

    updateMap(coordenadas: string, nombre: any, isMarkerClick: boolean): void {
      if (isMarkerClick) {
        this.search.setValue(nombre);  // Actualiza el valor solo si es un marcador
      }
      if (isPlatformBrowser(this.platformId)) {
        const [latitud, longitud] = coordenadas.split(',').map(Number);
    
        if (this.map) {
          // Eliminar el marcador existente si ya está creado
          if (this.marker) {
            this.map.removeLayer(this.marker); // Usamos `removeLayer` de la instancia del mapa
          }
    
          // Crear y agregar un nuevo marcador
          this.marker = this.L.marker([latitud, longitud], {
            draggable: false,
            icon: this.iconDefault, // Reutilizamos el ícono predeterminado
          }).addTo(this.map);
    
          // Centrar el mapa en las nuevas coordenadas
          this.map.setView([latitud, longitud], 20);
          this.map.invalidateSize(); // Redibujar el mapa si es necesario
        } else {
          console.error('Mapa no inicializado');
        }
      }
    }
}