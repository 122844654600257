<section class="section">
  <div class="container">
<!--    <div class="row justify-content-center">-->
<!--      <div class="col-12 text-center">-->
<!--        <div class="section-title mb-4 pb-2">-->
<!--          <h4 class="title mb-4">COMUNICACIONES</h4>-->
<!--          <p class="para-desc mb-0 mx-auto">Comunicaciones generales</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash;end col&ndash;&gt;-->
<!--    </div>-->

    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-2">COMUNICACIONES</h4>
            <p class="para-desc mx-auto mb-0">Comunicaciones generales</p>
          </div>
        </div>
      </div>
      <!--end row-->
      <!--    <app-blog [blogData]="blogData"></app-blog>-->
    </div>

    <div class="card blog blog-primary rounded border-0 shadow p-4 mb-4" *ngFor="let item of contentList">
      <h1 class="title mb-2">{{item.titulo}}</h1>
      <p>{{item.descripcion}}</p>
      <ngx-doc-viewer  [url]="item.link_pdf" viewer="url" style="height: 450px">
      </ngx-doc-viewer>
      <div class="row mt-4">
        <div class="col-sm-6">
          <h3>{{item.archivos_adicionales[0].tipo}}</h3>
        </div>
        <div class="col-sm-6">
<!--          <button class="btn btn-pills btn-primary" >Descargar</button>-->
          <a [href]="item.archivos_adicionales[0].link_descarga" target="_blank"
             class="btn btn-pills btn-primary  me-3"><i class="bi bi-download p-2"></i><b>{{item.archivos_adicionales[0].name}}</b></a>
        </div>
      </div>

      <hr>
    </div>

<!--    <div class="card shadow rounded border-0 overflow-hidden">-->
<!--      <img src="assets/images/valores.png" class="img-fluid" alt="">-->
<!--      <div class="card-body">-->
<!--        <h5 class="card-title">Saas & Software : Landrick</h5>-->
<!--        <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great importance</p>-->
<!--        <a href="javascript:void(0)" class="text-primary">Read More <i class="uil uil-angle-right-b align-middle"></i></a>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="card shadow rounded border-0 overflow-hidden">-->
<!--      <div class="row g-0">-->
<!--        <div class="col-md-5">-->
<!--          <img src="assets/images/work/14.jpg" class="img-fluid" alt="...">-->
<!--        </div>-->
<!--        <div class="col-md-7">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title">Saas & Software : Landrick</h5>-->
<!--            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>-->
<!--            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>

</section>





