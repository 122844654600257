import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-latest-news-item',
  standalone: true,
  imports: [],
  templateUrl: './latest-news-item.component.html',
  styleUrl: './latest-news-item.component.scss'
})

export class LatestNewsItemComponent {

  @Input() title: string = ''
  @Input() date: string = ''
  @Input() description: string = ''
  @Input() thumbnailAlt: string = '';
  @Input() thumbnailUrl: string = '';
  @Input() pathUrl: string = ''

  
}
