<section>
    <h5>¿Listo para tener tu Minicrédito?</h5>
    <a href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL"
        target="_blank">
        <img src="./../assets/images/minicreditos/google-play-dark.png" width="128px" />
    </a>
    <div>
        <a href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL"
            target="_blank">
            <img src="./../assets/images/minicreditos/qr-code.png" width="250px" />
        </a>
        <p>Escaneá el QR y descargá la app</p>

    </div>

</section>