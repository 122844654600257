@if(!dashBoardService.isDashboard){
  <app-header></app-header>
}
<router-outlet></router-outlet>
<app-footer></app-footer>
<a href="https://api.whatsapp.com/send/?phone={{telef}}&text={{msnWhatsapp}}.&type=phone_number&app_absent=0"
   class="btn-wsp btn-wsp-pulse" target="_blank">
  <i class="bi bi-whatsapp"></i>
</a>

<!--<google-map height="500px"-->

<!--            width="900px"-->

<!--            [center]="center"-->

<!--            [zoom]="zoom"-->

<!--            (mapClick)="moveMap($event)"-->

<!--            (mapMousemove)="move($event)">-->

<!--</google-map>-->



<!--<div>Latitude: {{display?.lat}}</div>-->

<!--<div>Longitude: {{display?.lng}}</div>-->
