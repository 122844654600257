<div style="padding-bottom: 150px">
  <section class="bg-half-170 d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-7 text-center">
          <img src="assets/images/celular%20simulador.png" alt="" style="max-height: 450px;">
        </div>
      </div>
    </div>
  </section>
  <!--<section class="section-two bg-primary">
    <div class="container">
      <div class="row" id="counter">
        <div class="col-md-3">
          <div class="counter-box text-center">
            &lt;!&ndash;          <h2 class="mb-0 mt-3 title-dark display-4 text-white">$ <span class="counter-value" [countUp]="9706873"&ndash;&gt;
            &lt;!&ndash;                                                                        [options]="option"></span></h2>&ndash;&gt;
            <h2 class="mb-0 mt-3 title-dark display-4 text-white">17</h2>
            <h5 class="counter-head title-dark text-white-50">Años de trayectoria</h5>
          </div>
          &lt;!&ndash;end counter box&ndash;&gt;
        </div>

        <div class="col-md-3 mt-3 pt-2 mt-sm-0 pt-sm-0">
          <div class="counter-box text-center">
            &lt;!&ndash;          <h2 class="mb-0 mt-3 title-dark display-4 text-white"><span class="counter-value" [countUp]="106" [options]="option"></span>&ndash;&gt;
            &lt;!&ndash;          </h2>&ndash;&gt;
            <h2 class="mb-0 mt-3 title-dark display-4 text-white">90.000</h2>
            <h5 class="counter-head title-dark text-white-50">Clientes</h5>
          </div>
          &lt;!&ndash;end counter box&ndash;&gt;
        </div>

        <div class="col-md-3 mt-3 pt-2 mt-sm-0 pt-sm-0">
          <div class="counter-box text-center">
            &lt;!&ndash;          <h2 class="mb-0 mt-3 title-dark display-4 text-white"><span class="counter-value" [countUp]="56043"&ndash;&gt;
            &lt;!&ndash;                                                                      [options]="option"></span></h2>&ndash;&gt;
            <h2 class="mb-0 mt-3 title-dark display-4 text-white">1.000</h2>
            <h5 class="counter-head title-dark text-white-50">Colaboradores</h5>
          </div>
          &lt;!&ndash;end counter box&ndash;&gt;
        </div>

        <div class="col-md-3 mt-3 pt-2 mt-sm-0 pt-sm-0">
          <div class="counter-box text-center">
            &lt;!&ndash;          <h2 class="mb-0 mt-3 title-dark display-4 text-white"><span class="counter-value" [countUp]="56043"&ndash;&gt;
            &lt;!&ndash;                                                                      [options]="option"></span></h2>&ndash;&gt;
            <h2 class="mb-0 mt-3 title-dark display-4 text-white">60</h2>
            <h5 class="counter-head title-dark text-white-50">puntos de atención en todo el país</h5>
          </div>
          &lt;!&ndash;end counter box&ndash;&gt;
        </div>
      </div>
      &lt;!&ndash;end row&ndash;&gt;
    </div>
    &lt;!&ndash;end container&ndash;&gt;
  </section>-->

  <section class="section-two bg-primary">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4 text-white"><b>NUESTRA APP</b></h4>
            <p class="para-desc mb-0 mx-auto text-white">Con nuestra APP vas a poder acceder:</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div>
        <div class="row">
          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center ">
              <img src="assets/images/icono/credito.png" alt="" class="img-about">
              <p class="title-home text-white">SIMULAR Y SOLICITAR TU CRÉDITO</p>
              <p class="subtitle-about text-white">En sucursal y en la APP</p>
              <p class="subtitle-about text-white">Via Whatsapp</p>
            </div>
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img src="assets/images/icono/Cajeros.png" alt="" class="img-about">
              <p class="title-home text-white">RETIRAR TU DINERO</p>
              <p class="subtitle-about text-white">en <b>+100 cajeros infonet</b> con <b>nuestra APP</b> o <b>en +70 sucursales</b></p>
            </div>
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img src="assets/images/icono/smartphone.com.png" alt="" class="img-about">
              <p class="title-home text-white">PAGAR +1.000 SERVICIOS</p>
              <p class="subtitle-about text-white">públicos y privados</p>
            </div>
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img src="assets/images/icono/pago_qr.png" alt="" class="img-about">
              <p class="title-home text-white">PAGO CON QR</p>
              <p class="subtitle-about text-white">en <b>+51.000 comercios</b> con nuestra APP</p>
            </div>
          </div>

        </div>
        <div class="col-12 text-center">
          <div class="mt-5">
            <a href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL" target="_blank" class="btn btn-pills bg-white me-3" style="color: #2f55d4"><i class="bi bi-download p-2"></i><b>DESCARGAR APP</b></a>
          </div>
        </div>
      </div>
    </div>
  </section>

<!--  <section class="py-5 d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-7 text-center">
          <img src="assets/images/cajero.png" alt="" style="max-height: 550px;">
          <div class="play-icon">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn">
              <i class="bi bi-play-fill text-primary rounded-circle shadow"></i>
            </a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                @if(isMobile){
                <iframe src="assets/video/NFD_CrediAgil_1080x1920.mp4" height="620" width="100%" frameborder="0"
                      allowfullscreen allow="autoplay; encrypted-media"></iframe>
                }@else{
                <iframe src="assets/video/NFD_CrediAgil_1920x1080.mp4" height="450" width="780" frameborder="0"
                    allowfullscreen allow="autoplay; encrypted-media"></iframe>
                }
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </section>-->

  <section class="py-5 d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12 text-center">

          @defer {
          <video autoplay muted loop class="d-block d-md-none"
                src="assets/video/NFD_CrediAgil_1080x1920.mp4">
         </video>

         <video autoplay muted loop class="d-none d-md-block"
                src="assets/video/NFD_CrediAgil_1920x1080.mp4">
         </video>
          } @placeholder (minimum 500ms) {
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
          }

        </div>
      </div>
    </div>
  </section>

</div>
