    <article class="latest-news-item">
        <img src={{thumbnailUrl}} class="img-desktop-noticias" alt={{thumbnailAlt}} height="256" width="370"/>

        <img src={{thumbnailUrl}} class="img-mobile-noticias" alt={{thumbnailAlt}} height="125"/>
        <div class="text-container">
            <span class="date">{{date}}</span>
            <p class="title">{{title}}</p>
            <p class="description">{{description}}</p>
            <a class="link" href="/noticias/{{pathUrl}}">Leer más</a>
        </div>
    </article>
    