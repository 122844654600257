<section class="faqs">
    <h4>Preguntas frecuentes</h4>
    <div class="faqs">
        @for (faq of faqs; track faq.id) {
            <div class="faq" (click)="toggle(faq.id)" [ngClass]="{'open': isOpen[faq.id]}">
                <div class="faq-question" >
                    <p>{{ faq.question }}</p>
                    <svg  width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.439745 0.915708C0.721678 0.649517 1.10385 0.5 1.50232 0.5C1.90078 0.5 2.28296 0.649517 2.56489 0.915708L8.01809 6.07082L13.4713 0.915707C13.7563 0.664623 14.1333 0.527929 14.5229 0.534426C14.9124 0.540923 15.2841 0.690105 15.5596 0.950539C15.8351 1.21097 15.9929 1.56233 15.9998 1.93058C16.0066 2.29883 15.862 2.65523 15.5964 2.92469L9.08067 9.08429C8.79873 9.35048 8.41656 9.5 8.01809 9.5C7.61963 9.5 7.23745 9.35048 6.95552 9.08429L0.439745 2.92469C0.158162 2.65816 3.23958e-07 2.29688 2.91027e-07 1.9202C2.58096e-07 1.54351 0.158162 1.18223 0.439745 0.915708Z" fill="black"/>
                        </svg>  
                </div>
                    <p class="faq-answer" >
                       {{ faq.answer }}
                    </p>
            </div>
            }
    </div>
</section>
