import { Routes } from '@angular/router';
import {HomeComponent} from "./component/home/home.component";
import {AboutComponent} from "./component/about/about.component";
import {ContactComponent} from "./component/contact/contact.component";
import {OfficesComponent} from "./component/offices/offices.component";
import {CreditComponent} from "./component/credit/credit.component";
import {SimulatorComponent} from "./component/simulator/simulator.component";
import {OurappComponent} from "./component/ourapp/ourapp.component";
import {CommunicationsComponent} from "./component/communications/communications.component";
import {NewsComponent} from "./component/news/news.component";
import {NewsDetailsComponent} from "./component/news-details/news-details.component";
import { MinicreditosComponent } from './component/minicreditos/minicreditos.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { authGuard } from './component/util/guards/auth.guard';

export const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'nosotros',component: AboutComponent},
  {path: 'app',component: OurappComponent},
  {path: 'simulador',component: SimulatorComponent},
  {path: 'noticias',component: NewsComponent},
  {path: 'noticias/:id',component: NewsDetailsComponent},
  {path: 'comunicaciones',component: CommunicationsComponent},
  {path: 'sucursales',component: OfficesComponent},
  {path: 'contacto', component: ContactComponent},
  {path: 'minicreditos', component: MinicreditosComponent},
  // {path: 'credito',component: CreditComponent},
  {
    path: 'credito',
    loadChildren: () => import('./component/credit/credit.routes').then(m => m.routes)
  },
  {
    path: 'solicitud-credito',  
    loadChildren: () => import('./component/checkout-extendido/checkout-extendido.routes').then(m => m.routes)
  },
  {
    path: 'mis-solicitudes',
    canActivate: [authGuard],
    component: DashboardComponent,
    loadChildren: () => import('./component/dashboard/dashboard.routes').then(m => m.routes)
  },
  {
    path:'**', 
    redirectTo: ''
  },


];
