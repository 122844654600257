<section>
    <h6>
        Contáctanos
    </h6>
    <div class="contact-info">
        <div class="item">
            <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 3.75C0 2.9875 0.302901 2.25623 0.842068 1.71707C1.38124 1.1779 2.1125 0.875 2.875 0.875H20.125C20.8875 0.875 21.6188 1.1779 22.1579 1.71707C22.6971 2.25623 23 2.9875 23 3.75V15.25C23 16.0125 22.6971 16.7438 22.1579 17.2829C21.6188 17.8221 20.8875 18.125 20.125 18.125H2.875C2.1125 18.125 1.38124 17.8221 0.842068 17.2829C0.302901 16.7438 0 16.0125 0 15.25V3.75ZM2.875 2.3125C2.49375 2.3125 2.12812 2.46395 1.85853 2.73353C1.58895 3.00312 1.4375 3.36875 1.4375 3.75V4.06194L11.5 10.0994L21.5625 4.06194V3.75C21.5625 3.36875 21.411 3.00312 21.1415 2.73353C20.8719 2.46395 20.5062 2.3125 20.125 2.3125H2.875ZM21.5625 5.73806L14.7948 9.799L21.5625 13.9634V5.73806ZM21.5136 15.6223L13.4061 10.6328L11.5 11.7756L9.59387 10.6328L1.48638 15.6209C1.56806 15.9268 1.74846 16.1971 1.99955 16.39C2.25064 16.5829 2.55838 16.6875 2.875 16.6875H20.125C20.4414 16.6875 20.749 16.5832 21.0001 16.3906C21.2512 16.198 21.4317 15.9279 21.5136 15.6223ZM1.4375 13.9634L8.20525 9.799L1.4375 5.73806V13.9634Z"
                    fill="black" />
            </svg>
            <div class="item-info">
                <strong>Email</strong>
                <a href="mailto:contacto@crediagil.com.py">contacto&#64;crediagil.com.py</a>
            </div>
        </div>
        <div class="item">
            <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.247 19.2634C9.39711 18.2198 10.4615 17.0855 11.43 15.8714C13.47 13.3084 14.711 10.7814 14.795 8.53443C14.8282 7.62123 14.6771 6.71069 14.3505 5.85721C14.024 5.00374 13.5288 4.22483 12.8945 3.56702C12.2602 2.90921 11.4998 2.386 10.6588 2.02864C9.81775 1.67127 8.91331 1.48709 7.9995 1.48709C7.08569 1.48709 6.18125 1.67127 5.34022 2.02864C4.49918 2.386 3.73881 2.90921 3.10451 3.56702C2.4702 4.22483 1.975 5.00374 1.64846 5.85721C1.32192 6.71069 1.17076 7.62123 1.204 8.53443C1.289 10.7814 2.531 13.3084 4.57 15.8714C5.53846 17.0855 6.60289 18.2198 7.753 19.2634C7.86367 19.3634 7.946 19.4361 8 19.4814L8.247 19.2634ZM7.262 20.4284C7.262 20.4284 0 14.3124 0 8.29443C0 6.1727 0.842855 4.13787 2.34315 2.63758C3.84344 1.13729 5.87827 0.294434 8 0.294434C10.1217 0.294434 12.1566 1.13729 13.6569 2.63758C15.1571 4.13787 16 6.1727 16 8.29443C16 14.3124 8.738 20.4284 8.738 20.4284C8.334 20.8004 7.669 20.7964 7.262 20.4284ZM8 11.0944C8.74261 11.0944 9.4548 10.7994 9.9799 10.2743C10.505 9.74923 10.8 9.03704 10.8 8.29443C10.8 7.55183 10.505 6.83964 9.9799 6.31453C9.4548 5.78943 8.74261 5.49443 8 5.49443C7.25739 5.49443 6.5452 5.78943 6.0201 6.31453C5.495 6.83964 5.2 7.55183 5.2 8.29443C5.2 9.03704 5.495 9.74923 6.0201 10.2743C6.5452 10.7994 7.25739 11.0944 8 11.0944ZM8 12.2944C6.93913 12.2944 5.92172 11.873 5.17157 11.1229C4.42143 10.3727 4 9.3553 4 8.29443C4 7.23357 4.42143 6.21615 5.17157 5.46601C5.92172 4.71586 6.93913 4.29443 8 4.29443C9.06087 4.29443 10.0783 4.71586 10.8284 5.46601C11.5786 6.21615 12 7.23357 12 8.29443C12 9.3553 11.5786 10.3727 10.8284 11.1229C10.0783 11.873 9.06087 12.2944 8 12.2944Z"
                    fill="black" />
            </svg>


            <div class="item-info">
                <strong>
                    Dirección
                </strong>
                <p>
                    Av. Mariscal López 1531 casi Libertad. <br>
                    Edificio Dylan primer piso. Fernando de la Mora</p>
            </div>
        </div>
        <div class="item">
            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.9999 9.80779V16.7308C17.9999 17.6489 17.6352 18.5293 16.9861 19.1785C16.3369 19.8277 15.4565 20.1924 14.5384 20.1924H9.69227C9.50866 20.1924 9.33257 20.1194 9.20274 19.9896C9.0729 19.8598 8.99997 19.6837 8.99997 19.5001C8.99997 19.3164 9.0729 19.1404 9.20274 19.0105C9.33257 18.8807 9.50866 18.8078 9.69227 18.8078H14.5384C15.0892 18.8078 15.6175 18.5889 16.007 18.1994C16.3965 17.8099 16.6153 17.2817 16.6153 16.7308H14.5384C13.9876 16.7308 13.4593 16.512 13.0698 16.1225C12.6803 15.733 12.4615 15.2048 12.4615 14.6539V11.1924C12.4615 10.6416 12.6803 10.1133 13.0698 9.72379C13.4593 9.3343 13.9876 9.11548 14.5384 9.11548H16.585C16.454 7.6763 15.9162 6.3041 15.0344 5.15917C14.1527 4.01424 12.9633 3.14384 11.6053 2.64964C10.2473 2.15544 8.7767 2.05784 7.3653 2.36825C5.9539 2.67865 4.65995 3.38425 3.6346 4.40261C2.35966 5.66084 1.57327 7.33116 1.41576 9.11548H3.46153C4.01236 9.11548 4.54063 9.3343 4.93013 9.72379C5.31962 10.1133 5.53844 10.6416 5.53844 11.1924V14.6539C5.53844 15.2048 5.31962 15.733 4.93013 16.1225C4.54063 16.512 4.01236 16.7308 3.46153 16.7308H2.07692C1.52608 16.7308 0.997812 16.512 0.608314 16.1225C0.218817 15.733 0 15.2048 0 14.6539V9.80779C0.001941 8.02501 0.532749 6.28289 1.52524 4.80193C2.51773 3.32096 3.92727 2.16774 5.57547 1.48822C7.22367 0.808711 9.03641 0.633457 10.7843 0.984644C12.5321 1.33583 14.1365 2.19767 15.3943 3.46108C16.2248 4.29554 16.8824 5.28569 17.3295 6.37478C17.7767 7.46387 18.0045 8.63049 17.9999 9.80779Z"
                    fill="black" />
            </svg>

            <div class="item-info">
                <strong>Consultas y sugerencias</strong>
                <a href="tel:595972261623">+595 972 261 623</a>
            </div>
        </div>
        <div class="item">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.75 7C12.75 6.80109 12.671 6.61032 12.5303 6.46967C12.3897 6.32902 12.1989 6.25 12 6.25C11.8011 6.25 11.6103 6.32902 11.4697 6.46967C11.329 6.61032 11.25 6.80109 11.25 7V12C11.2499 12.1272 11.2822 12.2522 11.3438 12.3635C11.4054 12.4747 11.4942 12.5685 11.602 12.636L14.602 14.511C14.7707 14.6166 14.9744 14.6508 15.1683 14.6061C15.2643 14.584 15.355 14.5433 15.4353 14.4861C15.5155 14.4289 15.5837 14.3565 15.636 14.273C15.6883 14.1895 15.7236 14.0965 15.7399 13.9993C15.7562 13.9022 15.7532 13.8027 15.7311 13.7067C15.709 13.6107 15.6683 13.52 15.6111 13.4397C15.5539 13.3595 15.4815 13.2913 15.398 13.239L12.75 11.584V7Z"
                    fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 3.25C9.67936 3.25 7.45376 4.17187 5.81282 5.81282C4.17187 7.45376 3.25 9.67936 3.25 12C3.25 14.3206 4.17187 16.5462 5.81282 18.1872C7.45376 19.8281 9.67936 20.75 12 20.75C14.3206 20.75 16.5462 19.8281 18.1872 18.1872C19.8281 16.5462 20.75 14.3206 20.75 12C20.75 9.67936 19.8281 7.45376 18.1872 5.81282C16.5462 4.17187 14.3206 3.25 12 3.25ZM4.75 12C4.75 11.0479 4.93753 10.1052 5.30187 9.22554C5.66622 8.34593 6.20025 7.5467 6.87348 6.87348C7.5467 6.20025 8.34593 5.66622 9.22554 5.30187C10.1052 4.93753 11.0479 4.75 12 4.75C12.9521 4.75 13.8948 4.93753 14.7745 5.30187C15.6541 5.66622 16.4533 6.20025 17.1265 6.87348C17.7997 7.5467 18.3338 8.34593 18.6981 9.22554C19.0625 10.1052 19.25 11.0479 19.25 12C19.25 13.9228 18.4862 15.7669 17.1265 17.1265C15.7669 18.4862 13.9228 19.25 12 19.25C10.0772 19.25 8.23311 18.4862 6.87348 17.1265C5.51384 15.7669 4.75 13.9228 4.75 12Z"
                    fill="black" />
            </svg>

            <div class="item-info">
                <strong>Horario de atención</strong>
                <p>De lunes a viernes 08:00 hs a 17:30 hs</p>
            </div>
        </div>
        <div class="item">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.48303 1.0433C3.70003 -0.166703 5.70403 0.0482968 6.72303 1.4103L7.98503 3.0943C8.81503 4.2023 8.74103 5.7503 7.75603 6.7293L7.51803 6.9673C7.49104 7.06721 7.4883 7.17211 7.51003 7.2733C7.57303 7.6813 7.91403 8.5453 9.34203 9.9653C10.77 11.3853 11.64 11.7253 12.054 11.7893C12.1583 11.8103 12.2661 11.8072 12.369 11.7803L12.777 11.3743C13.653 10.5043 14.997 10.3413 16.081 10.9303L17.991 11.9703C19.628 12.8583 20.041 15.0823 18.701 16.4153L17.28 17.8273C16.832 18.2723 16.23 18.6433 15.496 18.7123C13.686 18.8813 9.46903 18.6653 5.03603 14.2583C0.899027 10.1443 0.105027 6.5563 0.00402701 4.7883C-0.045973 3.8943 0.376027 3.1383 0.914027 2.6043L2.48303 1.0433ZM5.52303 2.3093C5.01603 1.6323 4.07203 1.5783 3.54003 2.1073L1.97003 3.6673C1.64003 3.9953 1.48203 4.3573 1.50203 4.7033C1.58203 6.1083 2.22203 9.3453 6.09403 13.1953C10.156 17.2333 13.907 17.3543 15.357 17.2183C15.653 17.1913 15.947 17.0373 16.222 16.7643L17.642 15.3513C18.22 14.7773 18.093 13.7313 17.275 13.2873L15.365 12.2483C14.837 11.9623 14.219 12.0563 13.835 12.4383L13.38 12.8913L12.85 12.3593C13.38 12.8913 13.379 12.8923 13.378 12.8923L13.377 12.8943L13.374 12.8973L13.367 12.9033L13.352 12.9173C13.3098 12.9565 13.2643 12.9919 13.216 13.0233C13.136 13.0763 13.03 13.1353 12.897 13.1843C12.627 13.2853 12.269 13.3393 11.827 13.2713C10.96 13.1383 9.81103 12.5473 8.28403 11.0293C6.75803 9.5113 6.16203 8.3693 6.02803 7.5033C5.95903 7.0613 6.01403 6.7033 6.11603 6.4333C6.17216 6.28137 6.25254 6.13953 6.35403 6.0133L6.38603 5.9783L6.40003 5.9633L6.40603 5.9573L6.40903 5.9543L6.41103 5.9523L6.69903 5.6663C7.12703 5.2393 7.18703 4.5323 6.78403 3.9933L5.52303 2.3093Z"
                    fill="black" />
            </svg>

            <div class="item-info">
                <strong>Ventas</strong>
                <a href="tel:595974565858">+595 974 565 858</a>
            </div>
        </div>

    </div>

</section>