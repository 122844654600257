
<section class="section mt-4 fondo">
  <div class="mt-n5 mt-sm-n7 mt-md-n8">
    
      <div class="p-md-6">
        <!-- Card header -->
        <div class="text-center">
          <h3 class="tituloSimulador">Simulador de Préstamos</h3>
          <p class="subtituloSimulador">¡Simulá y obtené tu préstamo al toque!</p>
        </div>
        <div class="col-11 col-lg-4  mx-auto card shadow" style="border-radius: 12px;">
        <!-- Card body & form -->
        <form [formGroup]="creditForm"  class="px-0 pb-0 mt-3">
          <div class="input-floating-label form-floating mb-4">
            
            <div class="container">
              <p style="color: #878686; font-weight: 700;">Monto del Préstamo</p>
              <label for="montoSlider" class="valoresSlider">Gs. {{ valueMonto | number }}</label>
              <input
                type="range"
                id="montoSlider"
                class="form-range"
                [min]="montos[0].value"
                [max]="montos[montos.length - 1].value"
                formControlName="monto"
                (input)="onMontoSliderInput($event)"
                [style.--slider-progress]="getMontoSliderProgress()"
              />
              <div class="maxMin mt-1">
                <p class="izquierda">Gs. {{ montos[0].value | number }}</p>
                <p class="derecha">Gs. {{ montos[montos.length - 1].value | number }}</p>
              </div>
            </div>
          </div>
        </form>

        
      </div>


      <div class="col-11 col-lg-4 mt-4  mx-auto card shadow" style="border-radius: 12px;">
        <!-- Card body & form -->
        <form [formGroup]="creditForm"  class="px-0 pb-0 mt-3">
          <div class="input-floating-label form-floating mb-4">
            
            <div  class="container" >
              <p   style="color: #878686; font-weight: 700;">Plazos</p>
              <label for="plazoSlider" class="valoresSlider">{{ sliderValue }} meses</label>
              <input
              type="range"
              id="plazoSlider"
              class="form-range"
              [min]="plazos[0].value"
              [max]="plazos[plazos.length - 1].value"
              formControlName="plazo"
              (input)="onSliderChange($event)"
              [style.--slider-progress]="getSliderProgress()"
            />
            <div class="maxMin mt-1">
              <p class="izquierda">{{ plazos[0].value }} meses</p>
              <p class="derecha">{{ plazos[plazos.length - 1].value }} meses</p>
            </div>
            </div>
          </div>
        </form>

        
      </div>
    </div>
  </div>

</section>

<div class="section-two bg-white mt-3">
  <div class=" justify-content-center" style="margin-top: -25px;">
    <div class="col-12 text-center">
      <div class="title-heading">
        <p class="para-desc mb-0 mx-auto ">{{getCant()}} cuotas de</p>
        <h1 class="mb-0 mx-auto importe-text" >Gs. {{getImporteCuota()}}</h1>
        <div class="text-center" style="margin-top: 25px;">
          <button class="btn btn-pills btn-primary btn-send" (click)="getCredito()">Solicitar Ahora</button>
        </div>

      </div>
    </div>
    <!--end col-->
  </div>
</div>