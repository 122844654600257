<section class="vh-100 d-flex align-items-center"
         style="background: url('assets/images/contact-detail.jpg') center center;" id="home">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
        <div class="title-heading mt-5 pt-4">
          <h1 class="heading">Contáctanos</h1>
          <p class="text-dark"> <span class="text-primary fw-bold">¿Tenés dudas o necesitás más información?</span> No dudes en contactarnos, estamos para darte la mejor atención. </p>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i class="bi bi-envelope text-dark me-3"></i>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Email</h5>
              <a href="mailto:contacto@crediagil.com.py" class="text-primary">contacto&#64;crediagil.com.py</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i class="bi bi-headset fea icon-m-md text-dark me-3"></i>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Consultas y/o sugerencias</h5>
              <a href="tel:+595 972 261 623" class="text-primary">+595 972 261 623</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i class="bi bi-telephone fea icon-m-md text-dark me-3"></i>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Ventas</h5>
              <a href="tel:+595 974 565 858" class="text-primary">+595 974 565 858</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i class="bi bi-geo-alt fea icon-m-md text-dark me-3"></i>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Dirección</h5>
              <a class="text-primary">Av. Mariscal López 1531 casi Libertad. Edificio Dylan primer piso. Fernando de la Mora.</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i class="bi bi-clock fea icon-m-md text-dark me-3"></i>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Horario de atención</h5>
              <a class="text-primary">De lunes a viernes 08:00 hs a 17:30 hs</a>
            </div>
          </div>

          <ul class="list-unstyled social-icon mb-0 mt-4">
            <li class="list-inline-item"><a href="https://www.facebook.com/Crediagil.py/?locale=es_LA" target="_blank" class="rounded">
              <i class="bi bi-facebook"></i>
            </a></li>
            <li class="list-inline-item ms-1"><a href="https://www.instagram.com/crediagil.py/?hl=es" target="_blank" class="rounded">
              <i class="bi bi-instagram"></i>
            </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
