<!--
<section class="section">
  <div class="container">

    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-2">NOTICIAS</h4>
            <p class="para-desc mx-auto mb-0">noticias generales</p>
          </div>
        </div>
      </div>
      &lt;!&ndash;end row&ndash;&gt;
      &lt;!&ndash;    <app-blog [blogData]="blogData"></app-blog>&ndash;&gt;
      <div *ngFor="let item of listNews">
        <div class="card blog blog-primary  shadow rounded border-0 overflow-hidden">
          <div class="row g-0">
            <div class="col-md-5">
              <img src="{{item.frontPage}}" class="img-fluid" alt="...">
            </div>
            <div class="col-md-7">
              <div class="card-body">
                <h4 class="card-title">{{item.title}}</h4>
&lt;!&ndash;                <h5 class="card-title">{{item.subtitle}}</h5>&ndash;&gt;
                <div class="card-text" [innerHTML]="item.content  | truncateHtml:60"></div>
                <a (click)="verMas(item)" class="text-primary"><b>Leer más </b><i class="uil uil-angle-right-b align-middle"></i></a>
                <p class="card-text"><small class="text-muted"> <i class="bi bi-calendar p-2"></i>{{item.date}}</small></p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="mt-5">
        <a routerLink="/credito" class="btn btn-pills btn-primary"><b>SOLICITAR AHORA</b></a>
      </div>
    </div>
  </div>
</section>
-->

<!--Blog Lists Start-->
<section class="section">
  <div class="container mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-2">NOTICIAS</h4>
          <h5 class="para-desc mx-auto mb-0">noticias generales</h5>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Blog Post Start -->
      <div class="col-lg-8 col-12">
        <div class="row">
          @for(data of paginatedNews;track $index){
          <div class="col-12 mb-4 pb-2">
            <div class="card blog rounded border-0 shadow overflow-hidden">
              <div class="row align-items-center g-0">
                <div class="col-md-6">
                  <img src="{{data.frontPage}}" class="img-fluid" alt="">
                  <div class="overlay bg-dark"></div>
                  <div class="author">
                    <small class="text-light user d-block"><i class="bi bi-person"></i> {{data.name}}</small>
                    <small class="text-light date"><i class="bi bi-calendar"></i> {{data.date}}</small>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-6">
                  <div class="card-body content">
                    <h5><a href="javascript:void(0)" (click)="verMas(data)" class="card-title title text-dark">{{data.title}}</a></h5>
<!--                    <p class="text-muted mb-0">{{data.content}}</p>-->
                      <div class="card-text" [innerHTML]="data.content  | truncateHtml:25"></div>
                    <div class="post-meta d-flex justify-content-between mt-3">
                      <ul class="list-unstyled mb-0">
<!--                        <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i-->
<!--                              class="uil uil-heart me-1"></i>{{data.like}}</a></li>-->
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                              class="bi bi-chat-square me-1"></i>{{data.message}}</a></li>
                      </ul>
                     <!-- <a routerLink="/page-blog-detail" class="text-muted readmore">Leer más <i
                          class="uil uil-angle-right-b align-middle"></i></a>-->
                          <a href="javascript:void(0)" (click)="verMas(data)" class="text-primary"><b>Leer más </b><i class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end blog post-->
          </div>
          }
          <!--end col-->
          <!-- PAGINATION START -->
          <div class="col-12">
            <ul class="pagination justify-content-center mb-0">
              <li class="page-item" [class.disabled]="currentPage === 1">
                <a class="page-link" href="javascript:void(0)" aria-label="Previous" (click)="changePage(currentPage - 1)">Anterior</a>
              </li>
              <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage">
                <a class="page-link" href="javascript:void(0)" (click)="changePage(page)">{{ page }}</a>
              </li>
              <li class="page-item" [class.disabled]="currentPage === totalPages">
                <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="changePage(currentPage + 1)">Siguiente</a>
              </li>
            </ul>
          </div>
          <!--end col-->
          <!-- PAGINATION END -->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
      <!-- Blog Post End -->

      <!-- START SIDEBAR -->
      <div class="col-lg-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="card border-0 sidebar sticky-bar ms-lg-4">
          <div class="card-body p-0">
            <!-- Author -->
            <div class="text-center">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Solicitá tu Crédito
                    </span>
                    <div class="mt-4">
                      <a routerLink="/credito" class="btn btn-pills btn-primary"><b>SOLICITAR AHORA</b></a>
                    </div>

            </div>
            <!-- Author -->

            <!-- RECENT POST -->
            <div class="widget mt-4">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                       Publicación reciente
                    </span>

              <div class="mt-4">
                @for(item of listNews.slice(0, 3);track $index){
                <div class="d-flex align-items-center mt-3">
                  <img src="{{item.frontPage}}" class="avatar avatar-small rounded" style="width: auto;" alt="">
                  <div class="flex-1 ms-3">
                    <a href="javascript:void(0)" (click)="verMas(item)" class="d-block title text-dark">{{item.title}}</a>
                    <span class="text-muted">{{item.date}}</span>
                  </div>
                </div>
                } @empty{
                <li>No hay noticias</li>
                }
                <!--<div class="d-flex align-items-center">
                  <img src="assets/images/blog/01.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                  <div class="flex-1 ms-3">
                    <a href="javascript:void(0)" class="d-block title text-dark">Consultant Business</a>
                    <span class="text-muted">15th April 2021</span>
                  </div>
                </div>

                <div class="d-flex align-items-center mt-3">
                  <img src="assets/images/blog/02.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                  <div class="flex-1 ms-3">
                    <a href="javascript:void(0)" class="d-block title text-dark">Grow Your Business</a>
                    <span class="text-muted">15th April 2021</span>
                  </div>
                </div>

                <div class="d-flex align-items-center mt-3">
                  <img src="assets/images/blog/03.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                  <div class="flex-1 ms-3">
                    <a href="javascript:void(0)" class="d-block title text-dark">Look On The Glorious Balance</a>
                    <span class="text-muted">15th April 2021</span>
                  </div>
                </div>-->
              </div>
            </div>
            <!-- RECENT POST -->

            <!-- SOCIAL -->
            <div class="widget mt-4">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Social Media
                    </span>

              <ul class="list-unstyled social-icon social text-center mb-0 mt-4">
                <li class="list-inline-item ms-1">
                  <a href="https://www.facebook.com/Crediagil.py/?locale=es_LA" style="font-size: 22px;" title="Facebook">
                    <i class="bi bi-facebook fa-lg"></i>
                  </a>
                </li>
                <li class="list-inline-item ms-1">
                  <a href="https://www.instagram.com/crediagil.py/?hl=es"  style="font-size: 22px;" title="Instagram">
                    <i class="bi bi-instagram fa-10x"></i>
                  </a>
                </li>
              </ul><!--end icon-->
            </div>
            <!-- SOCIAL -->
          </div>
        </div>
      </div><!--end col-->
      <!-- END SIDEBAR -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section -->
<!--Blog Lists End-->

<!-- Back to top -->
<!--<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">-->
<!--  <i-feather name="arrow-up" class="icons"></i-feather>-->
<!--</a>-->
<!-- Back to top -->
