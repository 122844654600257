import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {News} from "../component/news/news.component";

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  noticia: any ={} ;

  constructor(
    private http: HttpClient
  ) { }

  getListNews() {
    return this.http.get<any>('assets/data/news.json')
      .toPromise()
      .then(res => res.data as any[])
      .then(data => data);
  }

  getNewsBySlug(slug: string): Promise<any> {
    return this.http.get<any>('assets/data/news.json')
      .toPromise()
      .then(res => res.data as any[])
      .then(data => data.find(news => news.slug === slug));
  }

  getNews() {
    return this.noticia;
  }

  set setNews(noticia: News) {
    this.noticia = noticia;
  }
}
