<section class="bg-half-170 d-table w-100"  id="home">
  <div></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading title-heading">
          <h2 class="textoCabecera"> Nuestras sucursales </h2>
          <p class="subtituloCabecera">Más de 70 en todo el país</p>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<!-- Start -->
<section class="section">
  <div class="container">

    <div class="subcribe-form mb-4 position-relative ocultarpc">
      <form>
        <input name="nombre" id="nombre" type="text" class="form-control custom-input shadow-none"
               placeholder="Nombre o Dirección :" required [formControl]="search">
        <button type="submit" class="btn btn-pills btn-primary">Buscar</button>
      </form>
      <div class="form-underline"></div>
    </div>

    <div class="row responsive">
      <!-- Contenido dividido en dos columnas -->
      <div class="col-lg-5 col-md-7 contenedorSucursal">
        <!-- Cards de sucursales -->
        <div class="subcribe-form mb-4 position-relative ocultarResponsive">
          <form>
            <input name="nombre" id="nombre" type="text" class="form-control custom-input shadow-none"
                   placeholder="Nombre o Dirección :" required [formControl]="search">
            <button type="submit" class="btn btn-pills btn-primary">Buscar</button>
          </form>
          <div class="form-underline"></div>
        </div>
        @for (item of officesFilter; track item.nombre) {
        <div class="mb-4 sucursalSeleccionada" (click)="updateMap(item.coordenadas,item.nombre,false)">

              <div class="content mt-3">
                <div class="d-flex align-items-center justify-content-between">
                  <!-- Contenedor de texto -->
                  <div class="text-start">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="badge bg-soft rounded-pill">N°: {{item.numero}} | {{item.nombre}}</span>
                    </div>
                    <a routerLink="/account-profile" class="text-dark h6 name d-block">{{item.direccion}}</a>
                    <!-- <a href="tel:{{item.telefono}}"  -->
                      
                    <a href="https://api.whatsapp.com/send/?phone={{getNumeroWhatsapp(item.telefono)}}&text={{msnWhatsapp}}.&type=phone_number&app_absent=0"
                      type="button"
                      title="Ir a Whastapp"
                      target="_blank"
                      class="d-block fw-bold mt-1 text-foot">
                      <i class="bi bi-telephone-fill me-2"></i>{{item.telefono}}
                    </a>
                  </div>
                  <!-- Imagen -->
                  <div class="ms-3">
                    <!-- <img src="assets/images/sucursal(1).svg" class="avatar avatar-small rounded-pill shadow" alt=""> -->
                    <a href="https://www.google.com/maps/search/?api=1&query={{item.coordenadas}}&zoom=20" target="_blank" class="text-dark h5 mb-0 read-more"><i
                      class="bi bi-geo-alt-fill"></i></a>
                  </div>
                </div>
              </div>
            <!-- </div> -->
          <!-- </div> -->
        </div>
        }@empty{
        <h3><strong>No se encontraron sucursales que coincidan con los criterios de búsqueda.</strong></h3>
        }
      </div>
      <!-- Imagen en el lado derecho -->
      <div class="mapaDesktop" id="map"></div>
    </div>
  </div>
</section>

<!-- End -->
