<section class="why-minicreditos">
    <h2>¿Por qué usar los Minicréditos?</h2>
    <div>
        <img src="./../assets/images/minicreditos/credito-aprobado.png" width="550" class="img-desktop" data-aos="fade-right"
            alt="Imagen de un credito aprobado en la app de Crediagil" />
        <img src="./../assets/images/minicreditos/credito-aprobado.png" class="img-mobile" data-aos="fade-right"  data-aos-duration="700"
            alt="Imagen de un credito aprobado en la app de Crediagil" />
        <ul class="why-minicreditos-list">
            <li data-aos="fade-up" data-aos-duration="700">
                <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M21.4439 19.0986C21.1681 18.3186 20.6573 17.6432 19.9818 17.1656C19.3062 16.6879 18.4992 16.4315 17.6719 16.4316V15.4316H15.6719V16.4316C14.611 16.4316 13.5936 16.8531 12.8434 17.6032C12.0933 18.3534 11.6719 19.3708 11.6719 20.4316C11.6719 21.4925 12.0933 22.5099 12.8434 23.2601C13.5936 24.0102 14.611 24.4316 15.6719 24.4316V28.4316C14.8019 28.4316 14.0609 27.8766 13.7849 27.0986C13.7439 26.9714 13.6778 26.8536 13.5906 26.7523C13.5033 26.651 13.3966 26.5682 13.2768 26.5088C13.157 26.4495 13.0265 26.4147 12.893 26.4067C12.7596 26.3987 12.6258 26.4175 12.4998 26.462C12.3737 26.5066 12.2578 26.5759 12.159 26.666C12.0603 26.7561 11.9805 26.8651 11.9246 26.9866C11.8687 27.108 11.8377 27.2395 11.8334 27.3731C11.8292 27.5067 11.8518 27.6399 11.8999 27.7646C12.1756 28.5447 12.6864 29.2201 13.3619 29.6977C14.0375 30.1754 14.8445 30.4318 15.6719 30.4316V31.4316H17.6719V30.4316C18.7327 30.4316 19.7501 30.0102 20.5003 29.2601C21.2504 28.5099 21.6719 27.4925 21.6719 26.4316C21.6719 25.3708 21.2504 24.3534 20.5003 23.6032C19.7501 22.8531 18.7327 22.4316 17.6719 22.4316V18.4316C18.0855 18.4315 18.4889 18.5597 18.8267 18.7984C19.1645 19.0371 19.4199 19.3747 19.5579 19.7646C19.6462 20.0147 19.8302 20.2195 20.0695 20.3339C20.188 20.3906 20.3165 20.4233 20.4476 20.4303C20.5788 20.4373 20.71 20.4184 20.8339 20.3746C20.9577 20.3309 21.0717 20.2632 21.1694 20.1754C21.2671 20.0876 21.3465 19.9815 21.4031 19.863C21.4598 19.7445 21.4925 19.616 21.4995 19.4849C21.5065 19.3537 21.4876 19.2225 21.4439 19.0986ZM15.6719 18.4316C15.1414 18.4316 14.6327 18.6424 14.2576 19.0174C13.8826 19.3925 13.6719 19.9012 13.6719 20.4316C13.6719 20.9621 13.8826 21.4708 14.2576 21.8459C14.6327 22.2209 15.1414 22.4316 15.6719 22.4316V18.4316ZM17.6719 28.4316C18.2023 28.4316 18.711 28.2209 19.0861 27.8459C19.4611 27.4708 19.6719 26.9621 19.6719 26.4316C19.6719 25.9012 19.4611 25.3925 19.0861 25.0174C18.711 24.6424 18.2023 24.4316 17.6719 24.4316V28.4316Z"
                        fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5.82485 3.05264C8.27885 1.85164 12.3049 0.431641 16.7109 0.431641C21.0249 0.431641 24.9449 1.79264 27.3859 2.97764L27.5239 3.04464C28.2599 3.40864 28.8539 3.75264 29.2719 4.03164L25.5779 9.43164C34.0939 18.1376 40.6719 36.4286 16.7109 36.4286C-7.25014 36.4286 -0.849145 18.4696 7.74086 9.43164L4.07086 4.03164C4.35386 3.84664 4.71285 3.63164 5.14085 3.40364C5.35285 3.28964 5.58085 3.17197 5.82485 3.05264ZM23.2039 9.36064L26.1609 5.03764C23.4109 5.23564 20.1389 5.88164 16.9889 6.79364C14.7389 7.44364 12.2389 7.34464 9.92386 6.91764C9.34046 6.80963 8.76109 6.68088 8.18686 6.53164L10.1069 9.35864C14.2219 10.8236 19.0879 10.8236 23.2039 9.36064ZM8.95186 11.0616C13.7669 12.9216 19.5539 12.9216 24.3689 11.0596C26.3782 13.1788 28.0594 15.5864 29.3569 18.2026C30.7089 20.9606 31.4449 23.7176 31.3249 26.0936C31.2089 28.3866 30.3069 30.3456 28.2469 31.8016C26.0999 33.3186 22.4889 34.4286 16.7099 34.4286C10.9249 34.4286 7.29685 33.3376 5.12986 31.8376C3.05486 30.4006 2.14386 28.4676 2.01486 26.2056C1.87986 23.8556 2.59985 21.1126 3.94685 18.3356C5.23185 15.6876 7.02486 13.1386 8.95186 11.0616ZM7.80186 4.34764C8.60186 4.58564 9.43786 4.79264 10.2859 4.94964C12.4359 5.34564 14.5919 5.40364 16.4319 4.87064C18.576 4.24574 20.7569 3.75447 22.9619 3.39964C21.1219 2.84564 18.9699 2.43164 16.7099 2.43164C13.2649 2.43164 10.0519 3.39264 7.80186 4.34764Z"
                        fill="black" />
                </svg>

                <p>Obtené hasta 500.000 Gs con mínimos requisitos</p>
            </li>
            <li data-aos="fade-up" data-aos-duration="700">
                <svg width="30" height="48" viewBox="0 0 30 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.1667 28.8955H2L9.81667 2.09546H24.3333L17.6333 19.9621H28.8L10.9333 46.7621L13.1667 28.8955Z"
                        stroke="black" stroke-width="2.23333" stroke-linejoin="round" />
                </svg>


                <p>

                    Solicitalo de manera simple, fácil y rápida. <strong>Desembolsá al TOQUE</strong> en la billetera
                </p>

            </li>
            <li data-aos="fade-up" data-aos-duration="700">
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.9 23.3787C12.6685 24.4033 13.6649 25.235 14.8105 25.8077C15.956 26.3805 17.2192 26.6787 18.5 26.6787C19.7808 26.6787 21.044 26.3805 22.1895 25.8077C23.3351 25.235 24.3315 24.4033 25.1 23.3787M23.45 11.8287C23.45 11.8287 21.8 13.4787 21.8 15.1287C23.0375 13.4787 25.5125 13.4787 26.75 15.1287M13.55 11.8287C13.55 11.8287 15.2 13.4787 15.2 15.1287C13.9625 13.4787 11.4875 13.4787 10.25 15.1287M35 18.4287C35 27.5414 27.6127 34.9287 18.5 34.9287C9.3873 34.9287 2 27.5414 2 18.4287C2 9.31601 9.3873 1.92871 18.5 1.92871C27.6127 1.92871 35 9.31601 35 18.4287Z"
                        stroke="black" stroke-width="2.475" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <p>
                    Decile chau a lo complicado. Accedé a un minicrédito <strong>sin papeles y sin ir a una
                        sucursal</strong>
                </p>
            </li>

        </ul>
    </div>

</section>