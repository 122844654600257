import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GoogleTagManagerModule, GoogleTagManagerService} from "angular-google-tag-manager";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {TruncateHtmlPipe} from "./component/util/truncate-html.pipe";



@NgModule({
  declarations: [
    TruncateHtmlPipe
  ],
  imports: [
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    GoogleTagManagerModule.forRoot(
      {
        id: 'GTM-564BL9K',
      }
    )
  ],
  exports: [
    TruncateHtmlPipe
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: 'GTM-564BL9K'},
    GoogleTagManagerService,
    provideNgxMask(),
  ]
})
export class AppModule { }
