<div class="hero">
    <div class="hero-info" [ngClass]="{'hero-padding-bottom': home}"> 
        <h1>Descubrí los minicréditos</h1>
        <p>Obtené hasta <span class="yellow">500.000 Gs</span> para usar como quieras</p>
        <p>Solo disponibles en la <strong>Billetera Ágil</strong></p>
            <div class="hero-download">

                    <img id="qr-code" src="./../assets/images/minicreditos/qr-code.png" width="115"
                        alt="Codigo QR para descargar la app de Crediagil desde Play Store" />
                        
                    <a href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL" target="_blank">
            
                        <img id="play-store" src="./../assets/images/minicreditos/google-play.webp" width="140" alt="Logo de Play Store" />
                    </a>

            </div>
        
    </div>

    @if(!home){
        <div class="phone-simulator-bg">
            <div class="phone-simulator-container" data-aos="fade-up" data-aos-offset="400">
            <!-- <div class="phone-simulator-container" data-aos="zoom-in"> -->
                <img class="phone-simulator" src="./../assets/images/minicreditos/minicredito-simulador.png" width="284"
                    alt="Simulador de minicréditos en la app de Crediagil" />
                <div class="light"></div>
            </div>
    
        </div>
    }
</div>