import { Component } from '@angular/core';
import { LatestNewsItemComponent } from '../latest-news-item/latest-news-item.component';
// import { LatestNewsItemComponent } from '../latest-news-item/latest-news-item.component';
@Component({
  selector: 'app-latest-news',
  standalone: true,
  imports: [LatestNewsItemComponent],
  templateUrl: './latest-news.component.html',
  styleUrl: './latest-news.component.scss'
})
export class LatestNewsComponent {

  //TO DO --> obtener ultimos 3 items del CMS cuando este listo
  latestNews = [
    {
      id: 3,
      date: '24 mayo, 2024',
      title: 'Simulador de crédito',
      description: 'Un simulador de crédito es una herramienta esencial para cualquier persona que esté considerando la posibilidad de solicitar un préstamo. En Paraguay, esta herramienta se ha vuelto cada vez más popular debido a su capacidad para proporcionar una estimación precisa de los costos y las condiciones asociadas con diferentes tipos de créditos. Al utilizar un simulador de crédito, los usuarios pueden ingresar información clave, como el monto del préstamo, el plazo de amortización y la tasa de interés, para obtener una proyección detallada de sus pagos mensuales y el costo total del préstamo.',
      thumbnailUrl: 'https://www.crediagil.com.py/assets/images/noticias/simulador-de-credito.png',
      thumnbailAlt: 'Imagen animada de una calculadora de crédito',
      pathUrl: 'simulador-de-credito'
    },
    {
      id: 2,
      date: '22 abril, 2024',
      title: 'Casas de Crédito en Paraguay: 10 consejos',
      description: 'En el mundo financiero y de las casas de crédito de hoy, solicitar un préstamo se ha vuelto una práctica común para alcanzar objetivos personales y profesionales. Atrás quedaron los días en los que esta acción era vista con recelo, pues ahora, con la información adecuada, puede ser un paso inteligente hacia la realización de proyectos y sueños.',

      thumbnailUrl: 'https://www.crediagil.com.py/assets/images/noticias/casas-de-credito.png',
      thumbnailAlt: 'Imagen animada de con figuras relacionadas a casas de crédito',

      pathUrl: 'casas-de-credito-en-paraguay-10-consejos'
    },
    {
      id: 1,
      date: '16 marzo, 2024',
      title: 'Calculadora de Préstamos – 10 Tips para tus Metas Financieras',
      description: 'En la era digital, las herramientas financieras han evolucionado para adaptarse a las necesidades de los usuarios, y la “Calculadora de Préstamos” es una de esas innovaciones que está cambiando la forma en que las personas gestionan sus finanzas. Desde la comodidad de tu hogar o lugar de trabajo, ahora puedes acceder a esta herramienta que te permite calcular tus préstamos con precisión y rapidez. ¡Descubre cómo esta calculadora puede ser tu aliada en el camino hacia tus metas financieras!',
      thumbnailUrl: 'https://www.crediagil.com.py/assets/images/noticias/10_tips_financiera.png',
      thumbnailAlt: 'Imagen animada de una persona sosteniendo una calculadora',
      pathUrl: 'calculadora-de-prestamos'
    },
  ]
}
