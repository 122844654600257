import { Component, inject, OnDestroy } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterOutlet } from '@angular/router';
import { NavMenuComponent } from './layout/nav-menu/nav-menu.component';
import { ToolbarComponent } from './layout/toolbar/toolbar.component';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [SharedModule, RouterOutlet, NavMenuComponent, ToolbarComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnDestroy{
  dashBoardService = inject(DashboardService);

  constructor(){
    this.dashBoardService.isDashboard = true;
    
    if (typeof document !== 'undefined') {
      //oculta el whatsapp para acceder solo desde el boton correspondiente
      const element = document.querySelector('.btn-wsp') as HTMLElement;
    
      if (element) {
        element.style.display = 'none';
      }
  
      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'absolute';
        footer.style.bottom = '0px';
      }
    }
  }

  ngOnDestroy(): void {
    this.dashBoardService.isDashboard = false;
  }
}
