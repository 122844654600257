import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, of, switchMap, take } from 'rxjs';
import { AuthService } from '../../../services/dashboard/auth.service';
import { SessionService } from '../../../services/dashboard/session.service';
import { SnackbarService } from '../../../services/dashboard/snackbar-service.service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const sessionService = inject(SessionService);
  const snackbarService = inject(SnackbarService);

  const isUserAuthenticated$ = authService.isUserAuthenticated_v2();

  return isUserAuthenticated$.pipe(take(1),
      switchMap(isAuthenticated => {
          if(!isAuthenticated){
            return sessionService.verificarLocalStore()
          }else{
            return of(isAuthenticated)
          }
        }),
      map(status => {
        if (!status) {
          // snackbarService.showMessage('Necesitas loguearte para acceder al dashboard', 'danger', 3500)
          console.log('Necesitas loguearte para acceder al dashboard');
          return router.createUrlTree(['/solicitud-credito/login'])
        }

        return status
      })
    )
};