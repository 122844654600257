import {Component, inject, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {NewsService} from "../../services/news.service";
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {AppModule} from "../../app.module";

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    AppModule
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.css'
})
export class NewsComponent implements OnInit{
  serviceNews = inject(NewsService);
  // router= inject(Router);
  listNews:News[] = [];
  paginatedNews:News[] = [];
  totalItems = this.listNews.length;
  currentPage = 1;
  itemsPerPage = 5;
  pages: number[] = [];
  totalPages: number = 0;

  constructor(
    private  gtmService: GoogleTagManagerService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.router.events.forEach(item =>{
      if(item instanceof NavigationEnd){
        const gtmTag = {
          event: "noticias",
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    })
    this.getNews();

    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }

  /*listNews = [
      {
        "title": "CALCULADORA DE PRÉSTAMOS",
        "subtitle": "10 TIPS PARA TUS METAS FINANCIERAS",
        "contenido": [
          {
            "type": "text",
            "value": "En la era digital, las herramientas financieras han evolucionado para adaptarse a las necesidades de los usuarios, y la “Calculadora de Préstamos” es una de esas innovaciones que está cambiando la forma en que las personas gestionan sus finanzas. Desde la comodidad de tu hogar o lugar de trabajo, ahora puedes acceder a esta herramienta que te permite calcular tus préstamos con precisión y rapidez. ¡Descubre cómo esta calculadora puede ser tu aliada en el camino hacia tus metas financieras!"
          },
          {
            "type": "image",
            "value": "assets/images/noticias/calculadora_prestamos.png",
            "caption": "Figura con calculadora simbolizando la herramienta de préstamos."
          },
          {
            "type": "text",
            "value": "<h2>Guía de contenidos</h2><ul><li>Facilidad y Precisión al Alcance de un Clic</li><li>Planifica tu Futuro Financiero con Confianza</li><li>Controla tus Finanzas con Inteligencia y Eficiencia con la calculadora de préstamos</li><li>Aprovecha al Máximo tu Potencial Financiero</li><li>Optimiza tus Finanzas con la Calculadora de Préstamos</li><li>Considera la Flexibilidad de las Opciones de Pago</li><li>Analiza el Impacto de las Tasas de Interés en tus Finanzas</li><li>Planifica para el Futuro con una Estrategia de Pago Inteligente</li><li>Evita Sorpresas Desagradables con Cálculos Precisos</li><li>Empodérate con la Calculadora de Préstamos</li></ul>"
          },
          {
            "type": "text",
            "value": "<h3>Facilidad y Precisión al Alcance de un Click</h3><p>Con la “Calculadora de Préstamos”, decir adiós a las complicaciones y\n" +
              "confusiones al momento de planificar tus finanzas es más fácil que nunca.\n" +
              "Esta herramienta te brinda la posibilidad de obtener resultados precisos en\n" +
              "cuestión de segundos, lo que te permite tomar decisiones informadas y\n" +
              "seguras sobre tus préstamos. \n" +
              "<br>" +
              "Con solo ingresar algunos datos básicos, como el monto del préstamo, la\n" +
              "tasa de interés y el plazo de pago, podrás obtener información detallada\n" +
              "sobre tu préstamo, incluyendo la cantidad total a pagar y el monto de las\n" +
              "cuotas mensuales. ¡Descubre cómo esta calculadora puede simplificar tu vida\n" +
              "financiera!</p>"
          }
        ],
        "url": "assets/images/noticias/10_tips_financiera.png",
        "date": "29 marzo, 2024"
      }
    ]*/


  verMas(item:any ) {
    this.serviceNews.setNews = item;
    this.router.navigate(['noticias/'+item['slug']]);
  }

  getNews() {
    this.serviceNews.getListNews().then((data: any) => {
      this.listNews = data;
      this.totalItems = this.listNews.length;
      this.calculatePages();
      this.paginateNews();
    });

  }
  calculatePages() {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  paginateNews() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedNews = this.listNews.slice(start, end);
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.paginateNews();
    }
  }

  transform(value: string, limit: number): string {
    if (!value) return '';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const truncatedText = textContent.split(/\s+/).slice(0, limit).join(' ') + '...';
    return truncatedText;
  }

}

export  interface News {
  title: string;
  slug: string;
  date: string;
  name: string;
  content: any;
  frontPage: string;
  message: string;
}

export interface contenido {
  type: string;
  value: string;
  caption?: string;
}
