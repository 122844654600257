import {afterNextRender, Component, Input, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterLink,
    NgClass
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  isCondensed:boolean = false;
  miStyle = 'display: none';
  ngOnInit(): void {
  }

  constructor() {
  }

  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      this.miStyle = 'display: block';
      //document.getElementById("navigation").style.display = "block";
    } else {
      this.miStyle = 'display: none';
      //document.getElementById("navigation").style.display = "none";
    }
  }
}
